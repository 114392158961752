import React from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Tooltip } from '@mui/material';
import { styled } from '@mui/system';

// Custom Tooltip with larger font and custom background color
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: '#2a2d3e', // Dark theme background color
    color: '#ffffff', // White text color
    fontSize: '1.1rem', // Larger font size
    padding: '10px 15px', // Additional padding
    borderRadius: '8px', // Rounded corners
    maxWidth: 400, // Limit width for long URLs
  },
  [`& .MuiTooltip-arrow`]: {
    color: '#2a2d3e', // Match arrow color with background
  },
});

const RequestTable = ({ requestList }) => (
  <Box sx={{ flex: 1, padding: '1.5rem', backgroundColor: '#1e2133', color: '#fff', overflowY: 'auto', borderRadius: '8px' }}>
    <Typography variant="h6" sx={{ marginBottom: '1rem', color: '#56CCF2', fontSize: '1.4rem', fontWeight: 'bold' }}>
      Request Data
    </Typography>
    <TableContainer component={Paper} sx={{ maxHeight: 600, overflowY: 'auto', backgroundColor: '#2a2d3e', borderRadius: '8px' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {['Protocol', 'Status', 'IP', 'Port', 'Tag', 'First Seen', 'Hits', 'Malicious', 'SHA256', 'Risk Score', 'URL'].map((header, index) => (
              <TableCell
                key={index}
                sx={{
                  color: '#ffffff',
                  backgroundColor: '#343a40',
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                  padding: '10px 8px',
                  textAlign: 'center',
                }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {requestList.map((req, index) => (
            <TableRow
              key={index}
              sx={{
                '&:nth-of-type(odd)': { backgroundColor: '#2e3348' },
                '&:nth-of-type(even)': { backgroundColor: '#1e2133' },
                '&:hover': { backgroundColor: '#3b405a' },
              }}
            >
              <TableCell sx={{ padding: '8px', color: '#d1d5db', fontSize: '1rem', textAlign: 'center' }}>{req.response?.protocol || 'N/A'}</TableCell>
              <TableCell sx={{ padding: '8px', color: req.response?.status === 200 ? '#28a745' : '#dc3545', fontWeight: 'bold', fontSize: '1rem', textAlign: 'center' }}>
                {req.response?.status || 'N/A'}
              </TableCell>
              <TableCell sx={{ padding: '8px', color: '#d1d5db', fontSize: '1rem', textAlign: 'center' }}>{req.response?.ip || 'N/A'}</TableCell>
              <TableCell sx={{ padding: '8px', color: '#d1d5db', fontSize: '1rem', textAlign: 'center' }}>{req.response?.port || 'N/A'}</TableCell>
              <TableCell sx={{ padding: '8px', color: '#d1d5db', fontSize: '1rem', textAlign: 'center' }}>{req.response?.tag || 'N/A'}</TableCell>
              <TableCell sx={{ padding: '8px', color: '#d1d5db', fontSize: '1rem', textAlign: 'center' }}>{req.response?.first_seen || 'N/A'}</TableCell>
              <TableCell sx={{ padding: '8px', color: '#d1d5db', fontSize: '1rem', textAlign: 'center' }}>{req.response?.hits || 'N/A'}</TableCell>
              <TableCell sx={{ padding: '8px', color: req.response?.malicious ? '#dc3545' : '#28a745', fontWeight: 'bold', fontSize: '1rem', textAlign: 'center' }}>
                {req.response?.malicious ? 'Yes' : 'No'}
              </TableCell>
              <TableCell sx={{ padding: '8px', color: '#d1d5db', fontSize: '1rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 80, textAlign: 'center' }}>
                {req.response?.sha256 || 'N/A'}
              </TableCell>
              <TableCell sx={{ padding: '8px', color: '#d1d5db', fontSize: '1rem', textAlign: 'center' }}>{req.response?.risk_score || 'N/A'}</TableCell>
              <TableCell sx={{ padding: '8px', color: '#d1d5db', fontSize: '1rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 120, textAlign: 'center' }}>
                <CustomTooltip title={req.response?.url || 'N/A'} arrow placement="top" enterDelay={500}>
                  <span style={{ cursor: 'pointer', display: 'inline-block', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {req.response?.url || 'N/A'}
                  </span>
                </CustomTooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

export default RequestTable;
