// SidebarNav.js
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress
} from '@mui/material';
import {signOut} from './authUtils'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'; // Icon for flows
import IntegrationIcon from '@mui/icons-material/Extension';
import ChatIcon from '@mui/icons-material/Chat'; // Icon for Threat-AI
import InsightsIcon from '@mui/icons-material/Insights'; // Icon for Intelligence
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Import icon for sign out
import HistoryIcon from '@mui/icons-material/History'; // Icon for Scans
import ScansDialog from './ScansDialog'; // Import the ScansDialog component
// Import each integration dialog and components
import SlackIntegrationDialog from './integrations/SlackIntegrationDialog';
import GitHubIntegrationDialog from './integrations/GitHubIntegrationDialog';
import JiraIntegrationDialog from './integrations/JiraIntegrationDialog';
import ElasticsearchIntegrationDialog from './integrations/ElasticsearchIntegrationDialog';
import SplunkIntegrationDialog from './integrations/SplunkIntegrationDialog';
import HumioIntegrationDialog from './integrations/HumioIntegrationDialog';
import CrowdstrikeIntegrationDialog from './integrations/CrowdstrikeIntegrationDialog';
import VirustotalIntegrationDialog from './integrations/VirustotalIntegrationDialog';
import ZscalerIntegrationDialog from './integrations/ZscalerIntegrationDialog';
import DomainToolsIntegrationDialog from './integrations/DomainToolsIntegrationDialog';
import AlienvaultIntegration from './integrations/AlienvaultIntegrationDialog';
import CirclIntegrationDialog from './integrations/CirclIntegrationDialog';
import GoogleSafeIntegrationDialog from './integrations/GoogleSafeIntegrationDialog';
import GreynoiseIntegrationDialog from './integrations/GreynoiseIntegrationDialog';
import HudsonRockIntegrationDialog from './integrations/HudsonRockIntegrationDialog';
import NewsAPIIntegrationDialog from './integrations/NewsAPIIntegrationDialog';
import TwitterIntegrationDialog from './integrations/TwitterIntegrationDialog';
import PhishTankIntegrationDialog from './integrations/PhishTankIntegrationDialog';
import WhoisXMLIntegrationDialog from './integrations/WhoisXMLIntegrationDialog';
import WebamonIntegrationDialog from './integrations/WebamonIntegrationDialog';
import XSOARIntegrationDialog from './integrations/XSOARIntegrationDialog';
import ServiceNowIntegrationDialog from './integrations/ServiceNowIntegrationDialog';
import NessusIntegrationDialog from './integrations/NessusIntegrationDialog';
import AWSIntegrationDialog from './integrations/AWSIntegrationDialog';
import AzureIntegrationDialog from './integrations/AzureIntegrationDialog';
import GoogleCloudIntegrationDialog from './integrations/GoogleCloudIntegrationDialog';
import OktaIntegrationDialog from './integrations/OktaIntegrationDialog';
import FortinetIntegrationDialog from './integrations/FortinetIntegrationDialog';
import OnetrustIntegrationDialog from './integrations/OnetrustIntegrationDialog';
import Rapid7IntegrationDialog from './integrations/Rapid7IntegrationDialog';
import ProofpointIntegrationDialog from './integrations/ProofpointIntegrationDialog';
import WizIntegrationDialog from './integrations/WizIntegrationDialog';
import QualysIntegrationDialog from './integrations/QualysIntegrationDialog';
import TaniumIntegrationDialog from './integrations/TaniumIntegrationDialog';
import DuoIntegrationDialog from './integrations/DuoIntegrationDialog';
import McAfeeIntegrationDialog from './integrations/McAfeeIntegrationDialog';
import ExabeamIntegrationDialog from './integrations/ExabeamIntegrationDialog';
import MimecastIntegrationDialog from './integrations/MimecastIntegrationDialog';
import Integrations from './Integrations'; // Import the decoupled Integrations component




import ThreatAIChat from './ThreatAIChat';
import NewFlowDialog from './NewFlowDialog'; // New Flow Dialog component
import IntelligencePanel from './IntelligencePanel'; // Intelligence Component
import FlowDetailsDialog from './FlowDetailsDialog'; // Flow Details Dialog



const SidebarNav = () => {
  const [openIntegrations, setOpenIntegrations] = useState(false);
  const [openFlows, setOpenFlows] = useState(false);
  const [openIntelligence, setOpenIntelligence] = useState(false); // State for Intelligence panel
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [selectedFlow, setSelectedFlow] = useState(null); // State for selected flow
  const [openChat, setOpenChat] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [openNewFlowDialog, setOpenNewFlowDialog] = useState(false);
  const [openScansDialog, setOpenScansDialog] = useState(false); // State for Scans dialog
  const [scanFilterText, setScanFilterText] = useState(''); // Filter text for scans
  const integrationsRef = useRef(null);
    const [loading, setLoading] = useState(false); // Loading state for sign-out button
  const flowsRef = useRef(null);
  const intelligenceRef = useRef(null);

  const icons = [
//    { label: "Intelligence", icon: <InsightsIcon />, onClick: () => setOpenIntelligence(!openIntelligence) }, // Intelligence button
    { label: "Threat-AI", icon: <ChatIcon />, onClick: () => setOpenChat(!openChat) },
//    { label: "Flows", icon: <TrendingFlatIcon sx={{ transform: 'rotate(-45deg)' }} />, onClick: () => setOpenFlows(!openFlows) },
    { label: "Integrations", icon: <IntegrationIcon />, onClick: () => setOpenIntegrations(!openIntegrations) },
  ];

  const integrations = [
    { name: "Slack", key: "slack" },
    { name: "GitHub", key: "github" },
    { name: "Jira", key: "jira" },
    { name: "Elasticsearch", key: "elasticsearch" },
    { name: "Splunk", key: "splunk" },
    { name: "Humio", key: "humio" },
    { name: "Crowdstrike", key: "crowdstrike" },
    { name: "Virustotal", key: "virustotal" },
    { name: "Zscaler", key: "zscaler" },
    { name: "Domain Tools", key: "domaintools" },
    { name: "AlienVault", key: "alienvault" },
    { name: "Circl CVE", key: "circlcve" },
    { name: "Google Safe Browsing", key: "googlesafebrowsing" },
    { name: "Greynoise", key: "greynoise" },
    { name: "Hudson Rock", key: "hudsonrock" },
    { name: "News API", key: "newsapi" },
    { name: "Twitter", key: "twitter" },
    { name: "Phishtank", key: "phishtank" },
    { name: "Whois XML API", key: "whoisxml" },
    { name: "Webamon", key: "webamon" },
    { name: "XSOAR", key: "xsoar" },
    { name: "Service NOW", key: "servicenow" },
    { name: "Nessus", key: "nessus" },
    { name: "AWS", key: "aws" },
    { name: "Azure", key: "azure" },
    { name: "Google Cloud", key: "googlecloud" },
    { name: "Okta", key: "okta" },
    { name: "Fortinet", key: "fortinet" },
    { name: "OneTrust", key: "onetrust" },
    { name: "Rapid7", key: "rapid7" },
    { name: "Proofpoint", key: "proofpoint" },
    { name: "Wiz", key: "wiz" },
    { name: "Qualys", key: "qualys" },
    { name: "Tanium", key: "tanium" },
    { name: "Duo Security", key: "duosecurity" },
    { name: "McAfee", key: "mcafee" },
    { name: "Exabeam", key: "exabeam" },
    { name: "Mimecast", key: "mimecast" },
  ];

  const flows = [
    { name: "Data Sync", icon: <TrendingFlatIcon />, description: "Automated data synchronization" },
    { name: "Alert Trigger", icon: <TrendingFlatIcon />, description: "Trigger alerts based on conditions" },
    { name: "Log Aggregation", icon: <TrendingFlatIcon />, description: "Aggregate and analyze logs" },
    { name: "Incident Response", icon: <TrendingFlatIcon />, description: "Automated incident response" },
    { name: "Threat Detection", icon: <TrendingFlatIcon />, description: "Real-time threat detection" },
    { name: "User Behavior Analysis", icon: <TrendingFlatIcon />, description: "Analyze user behavior patterns" },
    { name: "Network Monitoring", icon: <TrendingFlatIcon />, description: "Monitor network traffic" },
    { name: "Data Enrichment", icon: <TrendingFlatIcon />, description: "Enrich data with external sources" },
    { name: "Vulnerability Scanning", icon: <TrendingFlatIcon />, description: "Automated vulnerability scanning" },
    { name: "Compliance Check", icon: <TrendingFlatIcon />, description: "Automate compliance checks" },
  ];

  const handleIntegrationClick = (integrationKey) => {
    setSelectedIntegration(integrationKey);
  };

  const handleFlowClick = (flow) => {
    setSelectedFlow(flow); // Open FlowDetailsDialog with selected flow
  };

  const handleCloseDialog = () => {
    setSelectedIntegration(null);
    setSelectedFlow(null);
  };

  // Close integrations, flows, or intelligence panel if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (integrationsRef.current && !integrationsRef.current.contains(event.target)) {
        setOpenIntegrations(false);
      }
      if (flowsRef.current && !flowsRef.current.contains(event.target)) {
        setOpenFlows(false);
      }
      if (intelligenceRef.current && !intelligenceRef.current.contains(event.target)) {
        setOpenIntelligence(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Filtered flows based on search input
  const filteredFlows = flows.filter(flow =>
    flow.name.toLowerCase().includes(filterText.toLowerCase())
  );



  const handleSignOut = async () => {
    setLoading(true);
    try {
      await signOut();
      // Handle successful sign-out and reset loading state
    } catch (error) {
      console.error("Error during sign out:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: 60,
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: '20px',
        zIndex: 1300,
      }}
    >
      <Box>
        {icons.map((item, index) => (
          <Tooltip key={index} title={item.label} placement="right">
            <IconButton
              href={item.link}
              onClick={item.onClick}
              sx={{
                color: '#ffffff',
                marginBottom: '20px',
                transition: 'color 0.3s',
                '&:hover': {
                  color: '#56CCF2',
                },
              }}
            >
              {item.icon}
            </IconButton>
          </Tooltip>
        ))}
      </Box>

      {/* Scans Section */}
<Tooltip title="Scans" placement="right">
        <IconButton
          onClick={() => setOpenScansDialog(true)}
          sx={{
            color: '#ffffff',
            transition: 'color 0.3s',
            '&:hover': { color: '#56CCF2' },
            marginBottom: "30px"
          }}
        >
          <HistoryIcon />
        </IconButton>
      </Tooltip>

      {/* Scans Dialog - using the existing ScansDialog component */}
      <ScansDialog open={openScansDialog} onClose={() => setOpenScansDialog(false)} />



  <Box sx={{ marginTop: 'auto' }}>
    <Tooltip title="Sign Out" placement="right">
      <IconButton
        onClick={handleSignOut}
        sx={{
          color: '#ffffff',
          transition: 'color 0.3s',
          '&:hover': { color: '#e53935' },
          'marginBottom': '1rem'
        }}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <ExitToAppIcon />
        )}
      </IconButton>
    </Tooltip>
  </Box>

      {/* Integrations List */}
      {openIntegrations && (
        <Box
          ref={integrationsRef}
          sx={{
            width: 240,
            position: 'absolute',
            left: 60,
            top: 0,
            bottom: 0,
            backgroundColor: '#191b2d',
            color: '#ffffff',
            padding: '10px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
            overflowY: 'auto',
            zIndex: 1250,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Integrations
          </Typography>
          <List>
            {integrations.map((integration, index) => (
              <ListItem button key={index} onClick={() => handleIntegrationClick(integration.key)}>
                <ListItemText primary={integration.name} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      {/* Flows List */}
      {openFlows && (
        <Box
          ref={flowsRef}
          sx={{
            width: 320,
            position: 'absolute',
            left: 60,
            top: 0,
            bottom: 0,
            backgroundColor: '#191b2d',
            color: '#ffffff',
            padding: '10px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
            overflowY: 'auto',
            zIndex: 1250,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Flows
          </Typography>
          <TextField
            placeholder="Search flows..."
            variant="outlined"
            fullWidth
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            sx={{
              marginBottom: '10px',
              backgroundColor: '#2b2f44',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#56CCF2',
                },
                '&:hover fieldset': {
                  borderColor: '#4a90e2',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#4a90e2',
                },
              },
              input: { color: '#ffffff' },
            }}
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#4a90e2',
              color: '#ffffff',
              marginBottom: '10px',
              '&:hover': { backgroundColor: '#56CCF2' },
              width: '100%',
            }}
            onClick={() => setOpenNewFlowDialog(true)}
          >
            New Flow
          </Button>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '12px',
            }}
          >
            {filteredFlows.map((flow, index) => (
              <Card
                key={index}
                onClick={() => handleFlowClick(flow)}
                sx={{
                  backgroundColor: '#2b2f44',
                  color: '#ffffff',
                  padding: '12px',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                  cursor: 'pointer',
                }}
              >
                <CardContent sx={{ padding: 0 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '4px',
                    }}
                  >
                    {flow.icon}
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {flow.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#bbbbbb' }}>
                      {flow.description}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Box>
      )}

      {/* Intelligence Panel */}
      {openIntelligence && <IntelligencePanel ref={intelligenceRef} />}

      {/* Scans Dialog */}


      {/* Render selected integration dialog */}
      {selectedIntegration === "slack" && (
        <SlackIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "github" && (
        <GitHubIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "jira" && (
        <JiraIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "elasticsearch" && (
        <ElasticsearchIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "splunk" && (
        <SplunkIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "humio" && (
        <HumioIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "crowdstrike" && (
        <CrowdstrikeIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "virustotal" && (
        <VirustotalIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "zscaler" && (
        <ZscalerIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "domaintools" && (
        <DomainToolsIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "alienvault" && (
              <AlienvaultIntegration open={true} onClose={handleCloseDialog} />
            )}
      {selectedIntegration === "circlcve" && (
        <DomainToolsIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "googlesafebrowsing" && (
        <GoogleSafeIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "greynoise" && (
        <GreynoiseIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "hudsonrock" && (
        <HudsonRockIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "newsapi" && (
        <NewsAPIIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "twitter" && (
        <TwitterIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "phishtank" && (
        <PhishTankIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "whoisxml" && (
        <WhoisXMLIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "webamon" && (
        <WebamonIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "xsoar" && (
        <XSOARIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "servicenow" && (
        <ServiceNowIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "nessus" && (
        <NessusIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "aws" && (
        <AWSIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "azure" && (
        <AzureIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "googlecloud" && (
        <GoogleCloudIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "okta" && (
        <OktaIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "fortinet" && (
        <FortinetIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "onetrust" && (
        <OnetrustIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "rapid7" && (
        <Rapid7IntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "proofpoint" && (
        <ProofpointIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "wiz" && (
        <WizIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "qualys" && (
        <QualysIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "tanium" && (
        <TaniumIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "duosecurity" && (
        <DuoIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "mcafee" && (
              <McAfeeIntegrationDialog open={true} onClose={handleCloseDialog} />
            )}
      {selectedIntegration === "exabeam" && (
              <ExabeamIntegrationDialog open={true} onClose={handleCloseDialog} />
            )}
      {selectedIntegration === "mimecast" && (
              <MimecastIntegrationDialog open={true} onClose={handleCloseDialog} />
            )}










      {/* Threat-AI Chatbox */}
      {openChat && <ThreatAIChat open={openChat} onClose={() => setOpenChat(false)} />}

      {/* New Flow Dialog */}
      {openNewFlowDialog && (
        <NewFlowDialog open={openNewFlowDialog} onClose={() => setOpenNewFlowDialog(false)} />
      )}

      {/* Flow Details Dialog */}
      {selectedFlow && (
        <FlowDetailsDialog
          open={Boolean(selectedFlow)}
          onClose={handleCloseDialog}
          flow={selectedFlow}
        />
      )}
      )}
    </Box>
  );
};

export default SidebarNav;
