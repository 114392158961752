import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Dialog, DialogActions, DialogContent, Typography, Box } from '@mui/material';

// Load your Stripe public key here
const stripePromise = loadStripe('your-publishable-key-here');

const StripePaymentDialog = ({ open, stripeLink }) => {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);

  // Function to open Stripe link in a new window
  const openStripeLink = () => {
    window.open(stripeLink, '_blank'); // Open Stripe link in a new tab
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm" // Set maxWidth for better sizing
      fullWidth

    >
      <DialogContent>


        <Box mb={2}>
          {/* Button to open Stripe payment in a new tab */}
          <Button
            onClick={openStripeLink}
            color="primary"
            variant="contained"
            fullWidth
          >
            Proceed to Stripe
          </Button>
        </Box>

        {error && <Typography color="error" variant="body2" align="center">{error}</Typography>}


      </DialogContent>
    </Dialog>
  );
};

const StripeDialogContainer = ({ open, stripeLink }) => {
  return (
    <Elements stripe={stripePromise}>
      <StripePaymentDialog open={open} stripeLink={stripeLink} />
    </Elements>
  );
};

export default StripeDialogContainer;
