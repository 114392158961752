// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Contact from './pages/Contact';
import SidebarNav from './components/SidebarNav';

// Utility function to check if the user is authenticated
const validateAccessToken = async () => {
  const token = localStorage.getItem('accessToken');
  console.log('token',token)
  if (!token) return false;

  try {
  console.log('hello')
    const response = await fetch('https://community.webamon.co.uk/results', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log(response.status)
    if (response.status === 200 || response.status === 429) {

      return true;
    } else {
      // If response is not 200, remove token and refresh page
      localStorage.removeItem('accessToken');
      window.location.reload();
      return false;
    }
  } catch (error) {
  console.log('hellodddd')
    console.error('Token validation failed:', error);
    localStorage.removeItem('accessToken');
    window.location.reload();
    return false;
  }
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      const validToken = await validateAccessToken();
      setIsAuthenticated(validToken);
    };
    checkAuthStatus();
  }, []);

  return (
    <Router>
      <div className="App" style={{ display: 'flex' }}>
        {isAuthenticated && <SidebarNav />} {/* Render SidebarNav only if authenticated */}
        <main style={{ flexGrow: 1, padding: '24px' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
