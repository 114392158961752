import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const WizIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Wiz Integration"
    integrationName="wiz"
  />
);


export default WizIntegrationDialog;
