import React, { useRef } from 'react';
import { Box, Typography, Grid, Paper, Button } from '@mui/material';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LineChart,
  Line,
} from 'recharts';
import GaugeChart from 'react-gauge-chart';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// Sample data for threat levels and metrics
const threatData = [
  { name: 'Low Risk', value: 40 },
  { name: 'Medium Risk', value: 30 },
  { name: 'High Risk', value: 20 },
  { name: 'Critical', value: 10 },
];

// Additional data and metrics
const riskMetrics = [
  { label: 'Total Incidents', value: 100 },
  { label: 'Active Threats', value: 12 },
  { label: 'Resolved Threats', value: 88 },
  { label: 'Recent Alerts', value: 5 },
];
const threatTrends = [
  { month: 'Jan', threats: 30 },
  { month: 'Feb', threats: 45 },
  { month: 'Mar', threats: 50 },
  { month: 'Apr', threats: 35 },
  { month: 'May', threats: 55 },
  { month: 'Jun', threats: 70 },
];
const COLORS = ['#4CAF50', '#FFB74D', '#FF9800', '#F44336'];

const ThreatOverview = () => {
  const dashboardContentRef = useRef();

  // Function to download dashboard as PNG
  const downloadAsPng = async () => {
    await new Promise(resolve => setTimeout(resolve, 500)); // Delay to ensure rendering
    html2canvas(dashboardContentRef.current, { scale: 3, backgroundColor: '#2b2f44' }).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png', 1.0); // High quality
      link.download = 'dashboard.png';
      link.click();
    });
  };

  // Function to download dashboard as PDF
  const downloadAsPdf = async () => {
    await new Promise(resolve => setTimeout(resolve, 500)); // Delay to ensure rendering
    html2canvas(dashboardContentRef.current, { scale: 3, backgroundColor: '#2b2f44' }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png', 1.0);
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('dashboard.pdf');
    });
  };

  return (
    <Box
      sx={{
        padding: 3,
        maxWidth: '100%',
        backgroundColor: '#2b2f44',
        borderRadius: '12px',
        boxShadow: '0 6px 30px rgba(0, 0, 0, 0.5)',
      }}
    >
      <Typography
        variant="h5"
        sx={{ color: '#56CCF2', marginBottom: 2, textAlign: 'center' }}
      >
        Threat Level Overview
      </Typography>

      {/* Download Buttons */}
      <Box sx={{ textAlign: 'center', marginBottom: 3 }}>
        <Button variant="contained" color="primary" onClick={downloadAsPng} sx={{ marginRight: 2 }}>
          Download as PNG
        </Button>
        <Button variant="contained" color="secondary" onClick={downloadAsPdf}>
          Download as PDF
        </Button>
      </Box>

      {/* Main content to capture (excluding download buttons) */}
      <Box ref={dashboardContentRef} sx={{ backgroundColor: '#2b2f44', padding: 3, borderRadius: '12px' }}>
        {/* Key Metrics Section */}
        <Grid container spacing={2} justifyContent="center" sx={{ marginBottom: 3 }}>
          {riskMetrics.map((metric, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper
                elevation={4}
                sx={{
                  padding: 3,
                  textAlign: 'center',
                  backgroundColor: '#3b3f56',
                  borderRadius: '12px',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.5)',
                  },
                }}
              >
                <Typography variant="h6" sx={{ color: '#ffffff' }}>
                  {metric.label}
                </Typography>
                <Typography variant="h4" sx={{ color: '#ffffff' }}>
                  {metric.value}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {/* Visualization Section */}
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              sx={{ color: '#ffffff', marginBottom: 2, textAlign: 'center' }}
            >
              Threat Level Distribution
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={threatData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  outerRadius={90}
                  labelLine={false}
                  label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                >
                  {threatData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend layout="horizontal" align="center" verticalAlign="bottom" />
              </PieChart>
            </ResponsiveContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              sx={{ color: '#ffffff', marginBottom: 2, textAlign: 'center' }}
            >
              Recent Threats Overview
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={threatData}>
                <XAxis dataKey="name" stroke="#ffffff" />
                <YAxis stroke="#ffffff" />
                <Tooltip />
                <Bar dataKey="value" fill="#56CCF2" />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>

        {/* Additional Insights Section */}
        <Grid container spacing={4} justifyContent="center" sx={{ marginTop: 4 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ color: '#ffffff', textAlign: 'center', marginBottom: 2 }}>
              Threat Trend Over Time
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={threatTrends}>
                <XAxis dataKey="month" stroke="#ffffff" />
                <YAxis stroke="#ffffff" />
                <Tooltip />
                <Line type="monotone" dataKey="threats" stroke="#56CCF2" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ color: '#ffffff', textAlign: 'center', marginBottom: 2 }}>
              Incident Resolution Rate
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
              <GaugeChart
                id="gauge-chart"
                nrOfLevels={20}
                colors={['#FF0000', '#00FF00']}
                arcWidth={0.3}
                percent={riskMetrics[2].value / riskMetrics[0].value}
                textColor="#FFFFFF"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ThreatOverview;
