import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const HudsonRockIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Hudson Rock Integration"
    integrationName="hudsonrock"
  />
);

export default HudsonRockIntegrationDialog;
