// FlowDetailsDialog.js
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, List, ListItem, ListItemText, Divider, Box, Tabs, Tab, Collapse } from '@mui/material';

const FlowDetailsDialog = ({ flow, onClose }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [expandedLogIndex, setExpandedLogIndex] = useState(null);

  const handleTabChange = (event, newIndex) => setTabIndex(newIndex);
  const toggleLogExpansion = (index) => setExpandedLogIndex(expandedLogIndex === index ? null : index);

  // Dummy data for flow details
  const steps = [
    { step: "Fetch Data", description: "Retrieve data from source" },
    { step: "Filter Events", description: "Filter events based on conditions" },
    { step: "Alert Trigger", description: "Send alert if conditions are met" },
    { step: "Report Generation", description: "Generate summary report" },
  ];

  const flowDetails = {
    isActive: true,
    integrations: ["Slack", "GitHub", "Jira"],
    createdBy: "John Doe",
    runCount: 42,
    previousLogs: [
      { timestamp: "2024-10-01 14:32", status: "Success", logDetails: { message: "Data processed successfully", itemsProcessed: 100 } },
      { timestamp: "2024-09-30 08:17", status: "Failed", logDetails: { message: "Connection timeout", retryCount: 3 } },
      { timestamp: "2024-09-29 12:44", status: "Success", logDetails: { message: "Data synced", syncDuration: "15s" } },
    ],
  };

  return (
    <Dialog
      open={!!flow}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: '#191b2d',
          color: '#ffffff',
          width: '700px',
          borderRadius: '10px',
        },
      }}
    >
      <DialogTitle sx={{ color: '#56CCF2' }}>{flow?.name} Details</DialogTitle>
      <DialogContent>
        {/* Tabs */}
        <Tabs value={tabIndex} onChange={handleTabChange} sx={{ marginBottom: '16px' }}>
          <Tab label="Flow Details" sx={{ color: '#56CCF2' }} />
          <Tab label="Execution Logs" sx={{ color: '#56CCF2' }} />
        </Tabs>

        {/* Tab Content */}
        {tabIndex === 0 && (
          <Box>
            <Typography variant="subtitle1" sx={{ color: '#bbbbbb', marginBottom: '16px' }}>
              {flow?.description}
            </Typography>

            {/* Flow Status and Metadata */}
            <Box sx={{ marginBottom: '16px' }}>
              <Typography variant="body1" sx={{ color: '#56CCF2' }}>Status: <span style={{ color: flowDetails.isActive ? '#4CAF50' : '#F44336' }}>{flowDetails.isActive ? "Active" : "Inactive"}</span></Typography>
              <Typography variant="body1" sx={{ color: '#56CCF2' }}>Integrations Used: <span style={{ color: '#ffffff' }}>{flowDetails.integrations.join(", ")}</span></Typography>
              <Typography variant="body1" sx={{ color: '#56CCF2' }}>Created By: <span style={{ color: '#ffffff' }}>{flowDetails.createdBy}</span></Typography>
              <Typography variant="body1" sx={{ color: '#56CCF2' }}>Run Count: <span style={{ color: '#ffffff' }}>{flowDetails.runCount}</span></Typography>
            </Box>

            {/* Flow Steps */}
            <Typography variant="h6" sx={{ color: '#56CCF2', marginBottom: '8px' }}>Flow Steps</Typography>
            <List>
              {steps.map((step, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemText
                      primary={step.step}
                      secondary={step.description}
                      primaryTypographyProps={{ color: '#ffffff', fontWeight: 'bold' }}
                      secondaryTypographyProps={{ color: '#bbbbbb' }}
                    />
                  </ListItem>
                  {index < steps.length - 1 && <Divider sx={{ backgroundColor: '#343b6f' }} />}
                </React.Fragment>
              ))}
            </List>
          </Box>
        )}

        {tabIndex === 1 && (
          <Box>
            <Typography variant="h6" sx={{ color: '#56CCF2', marginBottom: '8px' }}>Previous Execution Logs</Typography>
            <List>
              {flowDetails.previousLogs.map((log, index) => (
                <React.Fragment key={index}>
                  <ListItem button onClick={() => toggleLogExpansion(index)}>
                    <ListItemText
                      primary={`Executed at: ${log.timestamp}`}
                      secondary={`Status: ${log.status}`}
                      primaryTypographyProps={{ color: '#ffffff', fontWeight: 'bold' }}
                      secondaryTypographyProps={{ color: log.status === "Success" ? '#4CAF50' : '#F44336' }}
                    />
                  </ListItem>
                  <Collapse in={expandedLogIndex === index} timeout="auto" unmountOnExit>
                    <Box sx={{ backgroundColor: '#2b2f44', padding: '8px', borderRadius: '8px', margin: '8px 0' }}>
                      <Typography variant="body2" sx={{ color: '#bbbbbb', whiteSpace: 'pre-wrap' }}>
                        {JSON.stringify(log.logDetails, null, 2)}
                      </Typography>
                    </Box>
                  </Collapse>
                  {index < flowDetails.previousLogs.length - 1 && <Divider sx={{ backgroundColor: '#343b6f' }} />}
                </React.Fragment>
              ))}
            </List>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ color: '#56CCF2' }}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FlowDetailsDialog;
