import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const TwitterIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Twitter API Integration"
    integrationName="twitter"
  />
);


export default TwitterIntegrationDialog;
