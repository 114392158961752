import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const AWSIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="AWS Integration"
    integrationName="aws" // This will load fields for `slack` from integrations.json
  />
);

export default AWSIntegrationDialog;

