import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const FortinetIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Fortinet Integration"
    integrationName="fortinet"
  />
);

export default FortinetIntegrationDialog;
