import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const SlackIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Slack Integration"
    integrationName="slack" // This will load fields for `slack` from integrations.json
  />
);

export default SlackIntegrationDialog;
