// SignupForm.js
import React, { useState } from 'react';
import { Dialog, DialogContent, Button, Typography, Box, Container, TextField, CircularProgress } from '@mui/material';
import axios from 'axios';
import LoginForm from './LoginForm.js'
import AWS from 'aws-sdk';
import './SignupForm.css';

const SignupForm = ({ open, onClose }) => {
  const [email, setEmail] = useState('');
  const [view, setView] = useState('form'); // Toggle between form, verification prompt, password entry, and setting a new password
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null); // Success message state
  const [loading, setLoading] = useState(false); // Loading state for button
  const [password, setPassword] = useState(''); // Temporary password entered by user
  const [newPassword, setNewPassword] = useState(''); // New password set by user
  const [name, setName] = useState(''); // User's name
  const [session, setSession] = useState(null); // Cognito session token for new password challenge
  const [showSignInDialog, setShowSignInDialog] = useState(false); // State to control SignInDialog visibility

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage(null);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage(null);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setErrorMessage(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrorMessage(null);
  };

  const handleSignupSubmit = async (event) => {
    event.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    setLoading(true);
    try {
      await axios.post('https://community.webamon.co.uk/signup', { email });
      setView('verification'); // Show verification prompt after signup
    } catch (error) {
      console.error("Error during signup:", error);
      setErrorMessage("There was an issue signing you up. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();

    if (!password) {
      setErrorMessage("Please enter the temporary password sent to your email.");
      return;
    }

    setLoading(true);
    try {
      const cognito = new AWS.CognitoIdentityServiceProvider({
        region: 'eu-west-1', // Update with your AWS region
      });

      const params = {
        AuthFlow: 'USER_PASSWORD_AUTH',
        ClientId: '578gfrjo8hjvdrghpj9fbpn2k0', // Replace with your Cognito App Client ID
        AuthParameters: {
          USERNAME: email,
          PASSWORD: password,
        },
      };

      const response = await cognito.initiateAuth(params).promise();

      if (response.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
        setSession(response.Session); // Save session for the new password request
        setView('newPassword'); // Show new password form
      }
    } catch (error) {
      console.error("Error during password verification:", error);
      setErrorMessage("The password is incorrect or an error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

const handleNewPasswordSubmit = async (event) => {
  event.preventDefault();

  if (!newPassword || !name) {
    setErrorMessage("Please enter a new password and your name.");
    return;
  }

  setLoading(true);
  try {
    const cognito = new AWS.CognitoIdentityServiceProvider({
      region: 'eu-west-1', // Update with your AWS region
    });

    const params = {
      ChallengeName: 'NEW_PASSWORD_REQUIRED',
      ClientId: '578gfrjo8hjvdrghpj9fbpn2k0', // Replace with your Cognito App Client ID
      ChallengeResponses: {
        USERNAME: email,
        NEW_PASSWORD: newPassword,
        'userAttributes.name': name, // Setting the name attribute
      },
      Session: session,
    };

    const response = await cognito.respondToAuthChallenge(params).promise();
    const accessToken = response.AuthenticationResult.IdToken;

    // Store the access token locally
    localStorage.setItem('accessToken', accessToken);

    setSuccessMessage("Your password has been set successfully! You can now log in with your new password.");
    setTimeout(() => {
      setSuccessMessage(null);
      onClose(); // Close the dialog
      window.location.reload(); // Force a page reload after success
    }, 3000);
  } catch (error) {
    console.error("Error during new password setup:", error);
    setErrorMessage("Failed to set new password. Please try again.");
  } finally {
    setLoading(false);
  }
};


  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
    '& .MuiPaper-root': {
      backgroundColor: '#131629',
      borderRadius: '8px',
      boxShadow: 'none',
      width: '100%', // Ensures the dialog takes full available width
    },
      }}
    >
      <DialogContent sx={{ padding: '10px', borderRadius: '12px' }}>
        <Container>
          <Box className="signup-form-container" sx={{ textAlign: 'center', color: '#ffffff' }}>
            {view === 'form' && (
              <form onSubmit={handleSignupSubmit}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                  Join The Democracy
                </Typography>

                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  fullWidth
                  sx={{
                    marginBottom: '16px',
                    '& .MuiInputLabel-root': { color: '#bbb' },
                    '& .MuiInputBase-root': { color: '#ffffff', backgroundColor: '#2a2d3e' },
                    borderRadius: '8px',
                  }}
                />

                {errorMessage && (
                  <Typography variant="body2" sx={{ marginBottom: '20px', color: '#FF6666' }}>
                    {errorMessage}
                  </Typography>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={loading}
                  sx={{
                    padding: '14px',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    backgroundImage: 'linear-gradient(135deg, #4a90e2, #56CCF2)',
                    color: '#ffffff',
                    borderRadius: '8px',
                    '&:hover': {
                      backgroundImage: 'linear-gradient(135deg, #6d28d9, #ec4899)',
                      boxShadow: '0 8px 16px rgba(109, 40, 217, 0.5)',
                    },
                  }}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Sign Up"}
                </Button>
                <Typography
                                  variant="body2"
                                  sx={{
                                    marginTop: '10px',
                                    color: '#bbb',
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                  }}
                                  onClick={() => setShowSignInDialog(true)}
                                >
                                  Already have an account? Sign In
                                </Typography>


                            {/* SignInDialog */}
                            {showSignInDialog && <LoginForm open={showSignInDialog} onClose={() => setShowSignInDialog(false)} />}


              </form>

            )}

            {view === 'verification' && (
              <>
                <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                  Verify Your Email
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '30px', color: '#bbb' }}>
                  A password has been sent to <strong>{email}</strong>. Please check your inbox and enter the password below to complete your signup.
                </Typography>
                <Button
                  onClick={() => setView('passwordEntry')}
                  variant="contained"
                  fullWidth
                  sx={{
                    padding: '14px',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    backgroundImage: 'linear-gradient(135deg, #4a90e2, #56CCF2)',
                    color: '#ffffff',
                    borderRadius: '8px',
                    '&:hover': {
                      backgroundImage: 'linear-gradient(135deg, #6d28d9, #ec4899)',
                      boxShadow: '0 8px 16px rgba(109, 40, 217, 0.5)',
                    },
                  }}
                >
                  Enter Password
                </Button>
              </>
            )}

            {view === 'passwordEntry' && (
              <form onSubmit={handlePasswordSubmit}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                  Enter Password
                </Typography>
                <TextField
                  label="Password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  fullWidth
                  sx={{
                    marginBottom: '16px',
                    '& .MuiInputLabel-root': { color: '#bbb' },
                    '& .MuiInputBase-root': { color: '#ffffff', backgroundColor: '#2a2d3e' },
                    borderRadius: '8px',
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={loading}
                  sx={{
                    padding: '14px',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    backgroundImage: 'linear-gradient(135deg, #4a90e2, #56CCF2)',
                    color: '#ffffff',
                    borderRadius: '8px',
                    '&:hover': {
                      backgroundImage: 'linear-gradient(135deg, #6d28d9, #ec4899)',
                      boxShadow: '0 8px 16px rgba(109, 40, 217, 0.5)',
                    },
                  }}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Verify Password"}
                </Button>
              </form>
            )}

            {view === 'newPassword' && (
              <form onSubmit={handleNewPasswordSubmit}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                  One Last Step
                </Typography>
                <TextField
                  label="Full Name"
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                  fullWidth
                  sx={{
                    marginBottom: '16px',
                    '& .MuiInputLabel-root': { color: '#bbb' },
                    '& .MuiInputBase-root': { color: '#ffffff', backgroundColor: '#2a2d3e' },
                    borderRadius: '8px',
                  }}
                />
                <TextField
                  label="New Password"
                  type="password"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  fullWidth
                  sx={{
                    marginBottom: '16px',
                    '& .MuiInputLabel-root': { color: '#bbb' },
                    '& .MuiInputBase-root': { color: '#ffffff', backgroundColor: '#2a2d3e' },
                    borderRadius: '8px',
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={loading}
                  sx={{
                    padding: '14px',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    backgroundImage: 'linear-gradient(135deg, #4a90e2, #56CCF2)',
                    color: '#ffffff',
                    borderRadius: '8px',
                    '&:hover': {
                      backgroundImage: 'linear-gradient(135deg, #6d28d9, #ec4899)',
                      boxShadow: '0 8px 16px rgba(109, 40, 217, 0.5)',
                    },
                  }}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Set New Password"}
                </Button>
              </form>
            )}
          </Box>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default SignupForm;
