import React, { useState, useMemo } from 'react';
import { Box, Typography, Table, TableBody, TableRow, TableCell, TableContainer, Paper, Tabs, Tab, TextField, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Highlighter from 'react-highlight-words';
import { FixedSizeList as List } from 'react-window';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const LINE_HEIGHT = 20;

const Sha256Component = ({ results }) => {
  const parsedResults = typeof results === 'string' ? JSON.parse(results) : results;
  const dataToDisplay = parsedResults?.results || {};
  const resourceText = dataToDisplay.resource || '';

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [highlightIndex, setHighlightIndex] = useState(0);
  const [highlightCount, setHighlightCount] = useState(0);
  const [tabIndex, setTabIndex] = useState(0); // Track the selected tab

  // Debounce the search term for efficiency
  useMemo(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  // Calculate matches and update highlight count
  useMemo(() => {
    if (debouncedSearchTerm) {
      const regex = new RegExp(debouncedSearchTerm, 'gi');
      const matches = [...resourceText.matchAll(regex)];
      setHighlightCount(matches.length);
      setHighlightIndex(0); // Reset to first match on new search
    } else {
      setHighlightCount(0);
    }
  }, [debouncedSearchTerm, resourceText]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePrevious = () => {
    setHighlightIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : highlightCount - 1));
  };

  const handleNext = () => {
    setHighlightIndex((prevIndex) => (prevIndex < highlightCount - 1 ? prevIndex + 1 : 0));
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  // Split resource text into chunks for readability
  const resourceChunks = useMemo(() => {
    const CHUNK_SIZE = 100;
    const regex = new RegExp(`.{1,${CHUNK_SIZE}}`, 'g');
    return resourceText.match(regex) || [];
  }, [resourceText]);

  return (
    <Box sx={{ padding: '1.5rem', backgroundColor: '#1e2133', color: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <Typography variant="h4" sx={{ paddingBottom: '1rem', color: '#56CCF2', fontWeight: 'bold' }}>
        SHA256 Results
      </Typography>

      {/* Tabs for navigating between "General Data" and "Resource" */}
      <Tabs value={tabIndex} onChange={handleTabChange} sx={{ marginBottom: '1rem', borderBottom: 1, borderColor: 'divider' }}>
        <Tab label="General Data" sx={{ color: '#56CCF2' }} />
        <Tab label="Resource" sx={{ color: '#56CCF2' }} />
      </Tabs>

      {/* Content for "General Data" tab */}
      {tabIndex === 0 && (
        <TableContainer component={Paper} sx={{ backgroundColor: '#2a2d3e', marginBottom: '2rem' }}>
          <Table>
            <TableBody>
              {Object.entries(dataToDisplay).map(([key, value]) =>
                key !== 'resource' && (typeof value !== 'object' || value === null) ? (
                  <TableRow key={key}>
                    <TableCell sx={{ color: '#4ca1af', fontWeight: 'bold', fontSize: '1.3rem', padding: '12px' }}>
                      {key}
                    </TableCell>
                    <TableCell sx={{ color: '#e3e5e8', fontSize: '1.2rem', padding: '12px' }}>
                      {value || 'N/A'}
                    </TableCell>
                  </TableRow>
                ) : null
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Content for "Resource" tab */}
      {tabIndex === 1 && resourceText && (
        <Box>
          {/* Search Input for Resource */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <TextField
              label="Search within resource"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{
                backgroundColor: '#1e2133',
                input: { color: '#fff', fontSize: '1.1rem' },
              }}
              InputLabelProps={{ style: { color: '#4ca1af' } }}
            />
            {highlightCount > 0 && (
              <Box sx={{ marginLeft: '1rem', display: 'flex', alignItems: 'center', color: '#fff' }}>
                <IconButton onClick={handlePrevious} sx={{ color: '#4ca1af' }}>
                  <ArrowUpwardIcon />
                </IconButton>
                <Typography sx={{ margin: '0 0.5rem' }}>
                  {highlightIndex + 1}/{highlightCount}
                </Typography>
                <IconButton onClick={handleNext} sx={{ color: '#4ca1af' }}>
                  <ArrowDownwardIcon />
                </IconButton>
              </Box>
            )}
          </Box>

          {/* Virtualized List for Resource Text Chunks */}
          <Box sx={{ backgroundColor: '#2a2d3e', padding: '1rem', borderRadius: '4px', color: '#fff', maxHeight: 400, overflowY: 'auto', fontSize: '1.1rem' }}>
            <List
              height={400} // Height of the container
              itemCount={resourceChunks.length} // Number of lines/chunks to display
              itemSize={LINE_HEIGHT} // Height of each line/chunk
              width="100%" // Width of the container
            >
              {({ index, style }) => (
                <div style={{ ...style, whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                  <Highlighter
                    highlightClassName="highlight"
                    searchWords={[debouncedSearchTerm]}
                    autoEscape={true}
                    textToHighlight={resourceChunks[index] || ''}
                    activeIndex={highlightIndex}
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  />
                </div>
              )}
            </List>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Sha256Component;
