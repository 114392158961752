// src/pages/Contact.js
import React from 'react';

const Contact = () => {
  return (
    <div>
      <h1>Contact Us</h1>
      <p>This is the contact page. Reach out to us!</p>
    </div>
  );
};

export default Contact;
