import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, DialogActions, Box, Typography, List, ListItem, ListItemText, Divider, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import LoginDialog from '../LoginForm'; // Import your login dialog component
import UpgradeDialog from '../UpgradeDialog';


const IntegrationDialogTemplate = ({ open, onClose, title, integrationName }) => {
  const [fields, setFields] = useState([]);
  const [fieldValues, setFieldValues] = useState({ plugin_name: '' });
  const [currentIntegrations, setCurrentIntegrations] = useState([]);
  const [flows, setFlows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [flowsLoading, setFlowsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [deletingId, setDeletingId] = useState(null); // New state for loading spinner on delete
  const [showLoginDialog, setShowLoginDialog] = useState(false); // State to control login dialog visibility
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false); // State for UpgradeDialog


  useEffect(() => {
    const loadFields = async () => {
      try {
        const integrations = await import('./integration_keys.json');
        const fieldNames = integrations[integrationName];

        if (fieldNames) {
          setFields([{ label: 'plugin_name' }, ...fieldNames.map((name) => ({ label: name }))]);
          setFieldValues(fieldNames.reduce((acc, name) => ({ ...acc, [name]: '' }), { plugin_name: '' }));
        } else {
          console.warn(`No fields found for integration: ${integrationName}`);
          setFields([{ label: 'plugin_name' }]);
          setFieldValues({ plugin_name: '' });
        }
      } catch (error) {
        console.error(`Failed to load integrations:`, error);
      }
    };

    if (integrationName) {
      loadFields();
    }
  }, [integrationName]);

  const fetchCurrentIntegrations = async () => {
    setIsLoading(true);
    const accessToken = localStorage.getItem('accessToken');

    try {
      const response = await fetch(`https://community.webamon.co.uk/plugin?plugin_type=${integrationName}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });


           if (response.status === 401 || response.status === 403) {
                // If 401 or 403, show the login dialog
                setShowLoginDialog(true);
                setIsLoading(false);
                return;
              }


                  if (response.status === 429) {
                    // If 429, show the upgrade dialog
                    setShowUpgradeDialog(true);
                    setIsSaving(false);
                    return;
                  }

      if (response.ok) {
        const data = await response.json();
        const integrations = Object.values(data).map(plugin => ({
          plugin_name: plugin.plugin_name,
          plugin_id: plugin.plugin_id,
          last_modified: plugin.last_modified
        }));
        setCurrentIntegrations(integrations);
      } else {
        console.error('Failed to fetch integrations:', response.statusText);
        setCurrentIntegrations([]);
      }
    } catch (error) {
      console.error('Error fetching integrations:', error);
      setCurrentIntegrations([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchFlows = async () => {
      setFlowsLoading(true);
      const accessToken = localStorage.getItem('accessToken');

      try {
        const response = await fetch(`https://community.webamon.co.uk/flows?plugin_type=${integrationName}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });


             if (response.status === 401 || response.status === 403) {
                  // If 401 or 403, show the login dialog
                  setShowLoginDialog(true);
                  setIsLoading(false);
                  return;
                }


    if (response.status === 429) {
      // If 429, show the upgrade dialog
      setShowUpgradeDialog(true);
      setIsSaving(false);
      return;
    }

        if (response.ok) {
          const data = await response.json();
          setFlows(data);
        } else {
          console.error('Failed to fetch flows:', response.statusText);
          setFlows([]);
        }
      } catch (error) {
        console.error('Error fetching flows:', error);
        setFlows([]);
      } finally {
        setFlowsLoading(false);
      }
    };

    if (open && integrationName) {
      fetchFlows();
    }
  }, [open, integrationName]);

  const handleInputChange = (label, value) => {
    setFieldValues((prevValues) => ({ ...prevValues, [label]: value }));
  };

const handleSave = async () => {
  setIsSaving(true);
  try {
    const accessToken = localStorage.getItem('accessToken');
    const { plugin_name, ...credentials } = fieldValues;

    const response = await fetch('https://community.webamon.co.uk/plugin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        plugin_name,
        credentials,
        plugin_type: integrationName
      })
    });

    if (response.status === 401 || response.status === 403) {
      // If 401 or 403, show the login dialog
      setShowLoginDialog(true);
      setIsSaving(false);
      return;
    }

    if (response.status === 429) {
      // If 429, show the upgrade dialog
      setShowUpgradeDialog(true);
      setIsSaving(false);
      return;
    }

    if (response.ok) {
      console.log('Data saved successfully');
      await fetchCurrentIntegrations();
    } else {
      console.error('Failed to save data', response.statusText);
    }
  } catch (error) {
    console.error('Error saving data:', error);
  } finally {
    setIsSaving(false);
  }
};


  const handleDeleteIntegration = async (plugin_id) => {
    setDeletingId(plugin_id); // Set the deleting ID
    const accessToken = localStorage.getItem('accessToken');

    try {
      const response = await fetch(`https://community.webamon.co.uk/plugin/${plugin_id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });


           if (response.status === 401 || response.status === 403) {
                // If 401 or 403, show the login dialog
                setShowLoginDialog(true);
                setIsLoading(false);
                return;
              }

                  if (response.status === 429) {
                    // If 429, show the upgrade dialog
                    setShowUpgradeDialog(true);
                    setIsSaving(false);
                    return;
                  }

      if (response.ok) {
        console.log(`Plugin with ID ${plugin_id} deleted successfully.`);
        await fetchCurrentIntegrations();
      } else {
        console.error('Failed to delete plugin:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting plugin:', error);
    } finally {
      setDeletingId(null); // Clear the deleting ID
    }
  };

  useEffect(() => {
    if (open && integrationName) {
      fetchCurrentIntegrations();
    }
  }, [open, integrationName]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: '#191b2d',
          color: '#ffffff',
          width: '1200px',
          maxWidth: 'none',
          display: 'flex',
          flexDirection: 'row',
          overflow: 'hidden',
        },
      }}
    >


              {showLoginDialog && <LoginDialog open={showLoginDialog} onClose={() => setShowLoginDialog(false)} />}
              {showUpgradeDialog && <UpgradeDialog open={showUpgradeDialog} onClose={() => setShowUpgradeDialog(false)} />}
      <Box sx={{ width: '600px', padding: '12px' }}>
        <DialogTitle sx={{ color: '#ffffff', fontSize: '1.8rem' }}>{title}</DialogTitle>
        <DialogContent>
          {fields.map((field, index) => (
            <TextField
              key={index}
              fullWidth
              label={field.label}
              variant="outlined"
              margin="normal"
              InputLabelProps={{ style: { color: '#ffffff', fontSize: '1.2rem' } }}
              InputProps={{
                style: { color: '#ffffff', fontSize: '1.2rem' },
                sx: {
                  '& .MuiOutlinedInput-notchedOutline': { borderColor: '#56CCF2' },
                  '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#4a90e2' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#4a90e2' },
                },
              }}
              value={fieldValues[field.label] || ''}
              onChange={(e) => handleInputChange(field.label, e.target.value)}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} sx={{ color: '#ffffff', fontSize: '1.1rem', '&:hover': { backgroundColor: '#343b6f' } }}>Cancel</Button>
<Button
  onClick={handleSave}
  variant="contained"
  sx={{
    backgroundColor: '#56CCF2',
    color: '#ffffff',
    fontSize: '1.1rem',
    '&:hover': { backgroundColor: '#4a90e2' },
  }}
  disabled={isSaving} // Disable the button while saving
>
  {isSaving ? <CircularProgress size={28} color="secondary" /> : 'Save'}
</Button>

        </DialogActions>
      </Box>

      <Box sx={{ width: '600px', backgroundColor: '#1f2235', padding: '12px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '16px' }}>
        <Box sx={{ width: '300px' }}>
          <Typography variant="h6" sx={{ color: '#56CCF2', fontSize: '1.4rem', marginBottom: '8px' }}>Current Integrations</Typography>
          <List>
            {isLoading ? (
              <CircularProgress color="inherit" />
            ) : (
              currentIntegrations.map((integration, index) => (
                <ListItem key={index} sx={{ color: '#ffffff' }}>
                  <ListItemText
                    primary={integration.plugin_name}
                    secondary={`modified: ${integration.last_modified}`}
                    primaryTypographyProps={{ color: '#ffffff' }}
                    secondaryTypographyProps={{ color: '#B0B0B0' }}
                  />
                  <IconButton
                    onClick={() => handleDeleteIntegration(integration.plugin_id)}
                    sx={{ color: '#ffffff' }}
                    disabled={deletingId === integration.plugin_id}
                  >
                    {deletingId === integration.plugin_id ? (
                      <CircularProgress size={24} color="secondary" />
                    ) : (
                      <DeleteIcon />
                    )}
                  </IconButton>
                </ListItem>
              ))
            )}
          </List>
        </Box>

        <Box sx={{ width: '300px' }}>
          <Typography variant="h6" sx={{ color: '#56CCF2', fontSize: '1.4rem', marginBottom: '8px' }}>Flows</Typography>
          {flowsLoading ? (
            <CircularProgress color="inherit" />
          ) : (
            <List>
              {flows.map((flow, index) => (
                <ListItem key={index} sx={{ color: '#ffffff' }}>
                  <ListItemText
                    primary={flow.name}
                    secondary={flow.description}
                    primaryTypographyProps={{ color: '#ffffff' }}
                    secondaryTypographyProps={{ color: '#B0B0B0' }}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default IntegrationDialogTemplate;
