

export const signOut = async () => {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) {
    try {

      // Send the access token to the external API as "id_token"
      await fetch('https://community.webamon.co.uk/signout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id_token: accessToken }),
      });

      // Clear local storage
      localStorage.removeItem('accessToken');

      // Optional: Redirect user to the login page or home
      window.location.href = '/';
    } catch (error) {
      console.error("Error during sign out:", error);
    }
  }
};
