import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const ZscalerIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Zscaler Integration"
    integrationName="zscaler"
  />
);

export default ZscalerIntegrationDialog;
