import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const NewsAPIIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="News API Integration"
    integrationName="newsapi"
  />
);

export default NewsAPIIntegrationDialog;
