import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Grid, Card, CardContent, Slide } from '@mui/material';
import ThreatOverview from './ThreatOverview';
import CredentialExposure from './CredentialExposure';
import IncidentReports from './IncidentReports';
import VulnerabilityScans from './VulnerabilityScans';
import AuditTrailDashboard from './AuditTrailDashboard';
import TimelineComponent from './TimelineComponent';

const riskLevels = {
  low: { threshold: 33, color: '#2ecc71' },
  medium: { threshold: 66, color: '#f39c12' },
  high: { threshold: 100, color: '#e74c3c' },
};

const RiskTag = ({ score }) => {
  let level;
  let backgroundColor;

  if (score <= riskLevels.low.threshold) {
    level = 'Low';
    backgroundColor = riskLevels.low.color;
  } else if (score <= riskLevels.medium.threshold) {
    level = 'Medium';
    backgroundColor = riskLevels.medium.color;
  } else {
    level = 'High';
    backgroundColor = riskLevels.high.color;
  }

  return (
    <Box
      sx={{
        backgroundColor,
        borderRadius: '12px',
        padding: '4px 8px',
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        position: 'absolute',
        top: '8px',
        right: '8px',
      }}
    >
      {level} ({score})
    </Box>
  );
};

const Dashboards = ({ open, onClose }) => {
  const [selectedDashboard, setSelectedDashboard] = useState(null);

  const dashboardComponents = {
    'Threat Overview': <ThreatOverview />,
    'Credential Exposure': <CredentialExposure />,
    'Attack Paths': <IncidentReports />,
    '3rd Party Risk': <VulnerabilityScans />,
    'Audit Trial': <AuditTrailDashboard />,
    'Incident Timeline': <TimelineComponent />,
  };

  const dashboardData = [
    { title: 'Threat Overview', description: 'High-level executive summary of current threats', riskScore: 25 },
    { title: 'Credential Exposure', description: 'Overview of exposed credentials, backed by info stealer logs', riskScore: 70 },
    { title: 'Attack Paths', description: 'View current & previously exploited Threat-AI vectors', riskScore: 90 },
    { title: '3rd Party Risk', description: '3rd Parties which are susceptible to breaches', riskScore: 40 },
    { title: 'Audit Trial', description: 'Audit trail of user actions', riskScore: 60 },
    { title: 'Incident Timeline', description: 'Timeline of all available log sources', riskScore: 50 },
  ];

  const handleDashboardClick = (dashboard) => {
    setSelectedDashboard(dashboard.title);
  };

  const handleBackToDashboards = () => {
    setSelectedDashboard(null);
  };

  const handleCloseDialog = () => {
    setSelectedDashboard(null);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        style: {
          backgroundColor: '#191b2d',
          color: '#ffffff',
          maxWidth: '1200px',
          minHeight: '600px',
          borderRadius: '12px',
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle sx={{ color: '#56CCF2' }}>
        {selectedDashboard ? `${selectedDashboard} Details` : 'Dashboards'}
      </DialogTitle>

      <DialogContent sx={{ padding: 2, maxHeight: '80vh', overflowY: 'auto' }}>
        <Slide in={!selectedDashboard} direction="right">
          <Box sx={{ display: selectedDashboard ? 'none' : 'block', width: '100%' }}>
            <Typography variant="subtitle1" sx={{ color: '#bbbbbb', marginBottom: '16px', textAlign: 'center' }}>
              Select a dashboard to explore more details:
            </Typography>
            <Box sx={{ flexGrow: 1, width: '100%' }}>
              <Grid container spacing={4} justifyContent="center">
                {dashboardData.map((dashboard, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                      sx={{
                        background: dashboard.title === 'Incident Timeline'
                          ? 'linear-gradient(135deg, #FF6B6B 0%, #FFD93D 100%)'
                          : 'linear-gradient(135deg, #2b2f44 0%, #343b6f 100%)',
                        color: '#ffffff',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '12px',
                        cursor: 'pointer',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        boxShadow: '0px 6px 30px rgba(0, 0, 0, 0.5)',
                        position: 'relative',
                        height: '100%',
                        '&:hover': {
                          transform: 'scale(1.05)',
                          boxShadow: '0px 8px 40px rgba(0, 0, 0, 0.6)',
                        },
                      }}
                      onClick={() => handleDashboardClick(dashboard)}
                    >
                      <CardContent sx={{ textAlign: 'center' }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 'bold',
                            color: '#ffffff',
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
                          }}
                        >
                          {dashboard.title}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#ffffff', marginTop: '8px', fontWeight: 'bold', fontSize: '1.1rem' }}>
                          {dashboard.description}
                        </Typography>
                        <RiskTag score={dashboard.riskScore} />
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Slide>

        {selectedDashboard && (
          <Slide in={Boolean(selectedDashboard)} direction="left">
            <Box
              sx={{
                width: '100%',
                maxHeight: '80vh',
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                margin: 'auto',
              }}
            >
              {dashboardComponents[selectedDashboard]}
            </Box>
          </Slide>
        )}
      </DialogContent>

      <DialogActions>
        {selectedDashboard ? (
          <Button onClick={handleBackToDashboards} sx={{ color: '#56CCF2' }}>Back</Button>
        ) : (
          <Button onClick={handleCloseDialog} sx={{ color: '#56CCF2' }}>Close</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Dashboards;
