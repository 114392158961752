import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const OktaIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Okta Integration"
    integrationName="okta"
  />
);

export default OktaIntegrationDialog;
