import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const GitHubIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Github Integration"
    integrationName="github" // This will load fields for `slack` from integrations.json
  />
);

export default GitHubIntegrationDialog;
