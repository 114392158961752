import React, { useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Collapse, Divider, TextField, Tabs, Tab, TableContainer, Paper, TableCell, TableHead, TableRow, Table, TableBody} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PropTypes from 'prop-types';
import axios from 'axios';

// Helper function for TabPanel
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// Helper function for a11yProps
function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const StringComponent = ({ results }) => {
  const parsedResults = typeof results === 'string' ? JSON.parse(results) : results;

  // Access the 'results' key directly to display the inner data
  const dataToDisplay = parsedResults?.results || {};
  const domData = dataToDisplay.dom !== "No Results" ? dataToDisplay.dom : [];
  const resourcesData = dataToDisplay.resources !== "No Results" ? dataToDisplay.resources : [];
  // Tab state management
  const [tabValue, setTabValue] = useState(0);

  // Expand state for each resource row and search query state
  const [expandedRows, setExpandedRows] = useState({});
  const [searchQueries, setSearchQueries] = useState({});

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


  const getSubdomain = () => {
    // Check if the user is authenticated by looking for a token in local storage
    const token = localStorage.getItem("accessToken");  // Replace with your actual storage key
    if (token) {
      return { subdomain: "community", token };  // Return token along with subdomain
    } else {
      return { subdomain: "public", token: null };  // No token, just public subdomain
    }
  };


  const { subdomain, token } = getSubdomain();



  const handleExpandClick = async (sha256) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [sha256]: { ...prevExpandedRows[sha256], expanded: !prevExpandedRows[sha256]?.expanded, isLoading: true },
    }));

    if (!expandedRows[sha256]?.data) {
      // Perform POST request to fetch the data for the row being expanded
      try {
      const headers = token ? { Authorization: `Bearer ${token}` } : {};

        const response = await axios.post(`https://${subdomain}.webamon.co.uk/hunt`, {
          hunt_submission: sha256,
        },{headers});
        setExpandedRows((prevExpandedRows) => ({
          ...prevExpandedRows,
          [sha256]: { ...prevExpandedRows[sha256], data: response.data, isLoading: false },
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
        setExpandedRows((prevExpandedRows) => ({
          ...prevExpandedRows,
          [sha256]: { ...prevExpandedRows[sha256], data: { error: 'Failed to fetch data' }, isLoading: false },
        }));
      }
    }
  };

  // Handle search query changes
  const handleSearchChange = (sha256, event) => {
    setSearchQueries((prevQueries) => ({
      ...prevQueries,
      [sha256]: event.target.value,
    }));
  };

  // Filter resource data based on search query
  const getFilteredData = (data, searchQuery) => {
    if (!searchQuery) return data;
    const searchLower = searchQuery.toLowerCase();
    return JSON.stringify(data, null, 2).toLowerCase().includes(searchLower)
      ? data
      : { message: 'No matching results found.' };
  };

  return (
    <Box
      sx={{
        padding: 0,
        backgroundColor: '#1e2133',
        color: '#fff',
        borderRadius: '8px',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h5" sx={{ padding: '1rem', color: '#fff' }}>
        String Results - Limited to 10x for DOM & Resources
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="DOM and Resources Tabs"
          TabIndicatorProps={{ style: { backgroundColor: '#fff' } }}
        >
          <Tab
            label="DOM"
            {...a11yProps(0)}
            sx={{ color: tabValue === 0 ? '#fff' : '#bbb' }}
          />
          <Tab
            label="Resources"
            {...a11yProps(1)}
            sx={{ color: tabValue === 1 ? '#fff' : '#bbb' }}
          />
        </Tabs>
      </Box>

      {/* DOM Tab Panel */}
      <TabPanel value={tabValue} index={0}>
        {domData.length > 0 ? (
          <>
            <Typography variant="h6" sx={{ color: '#56CCF2', marginBottom: '1rem' }}>
              DOM Results
            </Typography>
            <TableContainer component={Paper} sx={{ backgroundColor: '#2a2d3e', marginBottom: '2rem', maxHeight: 400, overflowY: 'auto' }}>
              <Table sx={{ minWidth: 650 }} aria-label="DOM table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: '#56CCF2', fontWeight: 'bold' }}>Tag</TableCell>
                    <TableCell sx={{ color: '#56CCF2', fontWeight: 'bold' }}>Submission URL</TableCell>
                    <TableCell sx={{ color: '#56CCF2', fontWeight: 'bold' }}>Script Count</TableCell>
                    <TableCell sx={{ color: '#56CCF2', fontWeight: 'bold' }}>Risk Score</TableCell>
                    <TableCell sx={{ color: '#56CCF2', fontWeight: 'bold' }}>Domain Count</TableCell>
                    <TableCell sx={{ color: '#56CCF2', fontWeight: 'bold' }}>Submission UTC</TableCell>
                    <TableCell sx={{ color: '#56CCF2', fontWeight: 'bold' }}>Request Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {domData.map((domItem, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ color: '#fff' }}>{domItem.tag}</TableCell>
                      <TableCell sx={{ color: '#fff' }}>{domItem.meta.submission_url || 'N/A'}</TableCell>
                      <TableCell sx={{ color: '#fff' }}>{domItem.meta.script_count || 'N/A'}</TableCell>
                      <TableCell sx={{ color: '#fff' }}>{domItem.meta.risk_score || 'N/A'}</TableCell>
                      <TableCell sx={{ color: '#fff' }}>{domItem.meta.domain_count || 'N/A'}</TableCell>
                      <TableCell sx={{ color: '#fff' }}>{domItem.meta.submission_utc || 'N/A'}</TableCell>
                      <TableCell sx={{ color: '#fff' }}>{domItem.meta.request_count || 'N/A'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Typography variant="body1" color="error">
            No DOM results available.
          </Typography>
        )}
      </TabPanel>

      {/* Resources Tab Panel */}
      <TabPanel value={tabValue} index={1}>
        {resourcesData.length > 0 ? (
          <>
            <Typography variant="h6" sx={{ color: '#56CCF2', marginBottom: '1rem' }}>
              Resources Results
            </Typography>
            <Box sx={{ backgroundColor: '#2a2d3e', padding: '1rem', borderRadius: '8px', maxHeight: 400, overflowY: 'auto' }}>
              <List>
                {resourcesData.map((resourceItem, index) => (
                  <React.Fragment key={index}>
                    <ListItem sx={{ color: '#ffffff' }}>
                      <ListItemText
                        primary={
                          <Typography variant="body1" sx={{ color: '#ffffff' }}>
                            {`Feed: ${resourceItem.feed}`}
                          </Typography>
                        }
                        secondary={
                          <Typography variant="body2" sx={{ color: '#ffffff' }}>
                            {`SHA256: ${resourceItem.sha256} | MIME Type: ${resourceItem.mime_type} | Tags: ${resourceItem.tag?.join(', ') || 'N/A'}`}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => handleExpandClick(resourceItem.sha256)}>
                          {expandedRows[resourceItem.sha256]?.expanded ? (
                            <ExpandLessIcon sx={{ color: '#fff' }} />
                          ) : (
                            <ExpandMoreIcon sx={{ color: '#fff' }} />
                          )}
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Collapse in={expandedRows[resourceItem.sha256]?.expanded} timeout="auto" unmountOnExit>
                      <Box sx={{ padding: '1rem', backgroundColor: '#1e2133', color: '#ffffff', overflowX: 'auto', maxWidth: '100%' }}>
                        {expandedRows[resourceItem.sha256]?.isLoading ? (
                          <Typography>Loading...</Typography>
                        ) : expandedRows[resourceItem.sha256]?.data?.results?.results?.resource ? (
                          <>
                            <TextField
                              label="Search within resource data"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              sx={{ input: { color: '#fff' } }}
                              value={searchQueries[resourceItem.sha256] || ''}
                              onChange={(e) => handleSearchChange(resourceItem.sha256, e)}
                            />
                            <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', maxWidth: '100%' }}>
                              {JSON.stringify(getFilteredData(expandedRows[resourceItem.sha256].data.results.results.resource, searchQueries[resourceItem.sha256]), null, 2)}
                            </pre>
                          </>
                        ) : (
                          <Typography>No Data Available</Typography>
                        )}
                      </Box>
                    </Collapse>
                    <Divider sx={{ backgroundColor: '#444' }} />
                  </React.Fragment>
                ))}
              </List>
            </Box>
          </>
        ) : (
          <Typography variant="body1" color="error">
            No Resources results available.
          </Typography>
        )}
      </TabPanel>
    </Box>
  );
};

export default StringComponent;
