// IntelligencePanel.js
import React, { useState } from 'react';
import { Box, Typography, TextField, List, ListItem, ListItemText, Divider } from '@mui/material';
import IntelligenceReportDialog from './IntelligenceReportDialog';

const IntelligencePanel = React.forwardRef((props, ref) => {
  const [searchText, setSearchText] = useState('');
  const [selectedReport, setSelectedReport] = useState(null); // State to track selected report

  // Sample data for intelligence reports
  const intelligenceReports = [
    { title: 'Suspicious IP Activity', description: 'Detected unusual traffic from IP range 192.168.x.x' },
    { title: 'Malware Alert', description: 'Identified malware signatures in recent uploads' },
    { title: 'Data Breach', description: 'Potential data leak detected in database logs' },
    { title: 'Anomaly Detection', description: 'Unusual login locations identified' },
    { title: 'Phishing Campaign', description: 'New phishing campaign detected targeting executives' },
  ];

  // Filter intelligence reports based on search text
  const filteredReports = intelligenceReports.filter(report =>
    report.title.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Box
      ref={ref}
      sx={{
        width: 320,
        position: 'absolute',
        left: 60,
        top: 0,
        bottom: 0,
        backgroundColor: '#191b2d',
        color: '#ffffff',
        padding: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
        overflowY: 'auto',
        zIndex: 1250,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Actionable Intelligence
      </Typography>
      <TextField
        placeholder="Search Focused Intel..."
        variant="outlined"
        fullWidth
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        sx={{
          marginBottom: '10px',
          backgroundColor: '#2b2f44',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#56CCF2',
            },
            '&:hover fieldset': {
              borderColor: '#4a90e2',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#4a90e2',
            },
          },
          input: { color: '#ffffff' },
        }}
      />
      <List>
        {filteredReports.map((report, index) => (
          <React.Fragment key={index}>
            <ListItem
              button
              onClick={() => setSelectedReport(report)} // Open dialog with selected report
              sx={{ color: '#ffffff' }}
            >
              <ListItemText
                primary={report.title}
                secondary={report.description}
                primaryTypographyProps={{ color: '#ffffff', fontWeight: 'bold' }}
                secondaryTypographyProps={{ color: '#bbbbbb' }}
              />
            </ListItem>
            {index < filteredReports.length - 1 && <Divider sx={{ backgroundColor: '#343b6f' }} />}
          </React.Fragment>
        ))}
      </List>

      {/* Intelligence Report Dialog */}
      {selectedReport && (
        <IntelligenceReportDialog
          open={Boolean(selectedReport)}
          onClose={() => setSelectedReport(null)}
          report={selectedReport}
        />
      )}
    </Box>
  );
});

export default IntelligencePanel;
