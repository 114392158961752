import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const McAfeeIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="McAfee Integration"
    integrationName="mcafee"
  />
);

export default McAfeeIntegrationDialog;
