import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('authToken') || null);

  // Function to handle login and store token
  const login = (newToken) => {
    setToken(newToken);
    localStorage.setItem('authToken', newToken);  // Store token in localStorage
  };

  // Function to handle logout and clear token
  const logout = () => {
    setToken(null);
    localStorage.removeItem('authToken');
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('authToken');
    if (storedToken) setToken(storedToken);
  }, []);

  return (
    <AuthContext.Provider value={{ token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
