// AttackPathMap.js
import React, { useEffect, useRef } from 'react';
import { Network } from 'vis-network/standalone/esm/vis-network';
import 'vis-network/styles/vis-network.css';
import { Box, Typography } from '@mui/material';

const AttackPathMap = ({ nodesData, onNodeClick }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!nodesData || !containerRef.current) return;

    const nodes = nodesData.map((node) => ({
      id: node.id,
      label: node.label,
      title: `${node.label}\n${node.description}\nImpact: ${node.impact}\nSuggestions: ${node.suggestions}`,
      shape: node.impact === 'Critical' ? 'diamond' : node.impact === 'High' ? 'star' : 'ellipse',
      color: {
        background: node.impact === 'Critical' ? '#ff6347' : node.impact === 'High' ? '#FFA500' : '#4CAF50',
        border: node.impact === 'Critical' ? '#FF4500' : node.impact === 'High' ? '#FF8C00' : '#66BB6A',
        highlight: { border: '#FFD700', background: '#FFEB3B' }
      },
      font: { size: node.impact === 'Critical' ? 16 : 14, color: '#FFFFFF', face: 'Roboto' },
      scaling: { min: 16, max: 32 },
    }));

    const edges = nodesData.flatMap((node) =>
      node.connectedTo.map((targetId) => ({
        from: node.id,
        to: targetId,
        color: {
          color: node.impact === 'Critical' ? '#FF6347' : node.impact === 'High' ? '#FFA500' : '#66BB6A',
          opacity: 0.7
        },
        width: node.impact === 'Critical' ? 4 : node.impact === 'High' ? 3 : 2,
        arrows: { to: { enabled: true, scaleFactor: 1.2 } },
        smooth: { type: 'curvedCW', roundness: 0.5 },
      }))
    );

    const network = new Network(containerRef.current, { nodes, edges }, {
      nodes: {
        shapeProperties: { interpolation: false },
        shadow: true,
      },
      edges: {
        smooth: { type: 'dynamic' },
        shadow: true,
      },
      physics: {
        enabled: true,
        stabilization: { iterations: 200, fit: true },
      },
      interaction: {
        tooltipDelay: 200,
        hover: true,
        navigationButtons: true,
        zoomView: true,
        selectable: true,
      },
      layout: {
        improvedLayout: true,
      },
    });

    network.on("click", function (params) {
      if (params.nodes.length > 0 && onNodeClick) {
        const nodeId = params.nodes[0];
        const selectedNode = nodesData.find(node => node.id === nodeId);
        onNodeClick(selectedNode);
      }
    });

  }, [nodesData, onNodeClick]);

  return (
    <Box sx={{ position: 'relative' }}>
      <div ref={containerRef} style={{ height: '500px', borderRadius: '12px', backgroundColor: '#1f2233' }} />

      {/* Enhanced Legend */}
      <Box
        sx={{
          position: 'absolute',
          top: 10,
          left: 10,
          backgroundColor: '#1f2233',
          borderRadius: '8px',
          padding: '10px',
          color: '#FFFFFF',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)',
        }}
      >
        <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 'bold', marginBottom: '5px' }}>
          Legend:
        </Typography>
        <Typography variant="body2" sx={{ color: '#ff6347', marginBottom: '5px' }}>
          Critical Path - Diamond Shape, Red Border
        </Typography>
        <Typography variant="body2" sx={{ color: '#FFA500', marginBottom: '5px' }}>
          High Path - Star Shape, Orange Border
        </Typography>
        <Typography variant="body2" sx={{ color: '#4CAF50' }}>
          Low Path - Ellipse Shape, Green Border
        </Typography>
      </Box>

      {/* Enhanced Instructions */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 10,
          left: 10,
          backgroundColor: '#1f2233',
          borderRadius: '8px',
          padding: '10px',
          color: '#FFFFFF',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)',
        }}
      >
        <Typography variant="body2" sx={{ color: '#FFFFFF' }}>
          Use navigation buttons to zoom and pan. Click on nodes for more details.
        </Typography>
      </Box>
    </Box>
  );
};

export default AttackPathMap;
