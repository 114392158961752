import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const DuoIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Duo Integration"
    integrationName="duosecurity"
  />
);


export default DuoIntegrationDialog;
