import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const HumioIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Humio Integration"
    integrationName="humio"
  />
);

export default HumioIntegrationDialog;
