import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableRow, TableCell, TableContainer, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublicIcon from '@mui/icons-material/Public';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import StorageIcon from '@mui/icons-material/Storage';
import ReactJson from 'react-json-view';

const Domains = ({ domainList, handleCopy }) => (
  <Box sx={{ padding: '2rem', backgroundColor: '#2a2d3e', color: '#fff' }}>
    {domainList.length > 0 ? (
      domainList.map((domain, index) => (
        <Accordion key={index} sx={{ backgroundColor: '#1e2133', marginBottom: '1rem' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#56CCF2' }} />}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#56CCF2' }}>
              {domain.name || 'Unknown Domain'}
              {domain.asn?.name ? ` - ${domain.asn.name}` : ''}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#1e2133', color: '#fff' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>

              {/* General Information */}
              <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#56CCF2' }}>General Information</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', paddingLeft: '1rem' }}>
                  <Typography><strong>IP:</strong> {domain.ip || 'N/A'}</Typography>
                  <Typography><strong>Server:</strong> {domain.server || 'N/A'}</Typography>
                  <Typography><strong>Hosting Scripts:</strong> {domain.hosting_scripts ? 'Yes' : 'No'}</Typography>
                  <Typography><strong>Request Count:</strong> {domain.request_count || 'N/A'}</Typography>
                  <Typography><strong>Total Response Size:</strong> {domain.total_response_size || 'N/A'} bytes</Typography>
                </Box>
              </Box>

              {/* ASN Information */}
              <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#56CCF2' }}>ASN Information</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', paddingLeft: '1rem' }}>
                  <Typography><strong>ASN Number:</strong> {domain.asn?.number || 'N/A'}</Typography>
                  <Typography><strong>ASN Name:</strong> {domain.asn?.name || 'N/A'}</Typography>
                  <Typography><strong>Network:</strong> {domain.asn?.network || 'N/A'}</Typography>
                </Box>
              </Box>

              {/* WHOIS Information */}
              <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#56CCF2' }}>WHOIS Information</Typography>
                <ReactJson
                  src={domain.whois || {}}
                  theme="ocean"
                  iconStyle="circle"
                  collapsed={1}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  enableClipboard={handleCopy}
                  style={{ backgroundColor: 'transparent', fontSize: '1rem' }}
                />
              </Box>

              {/* DNS Information with Icons */}
              <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#56CCF2' }}>DNS Information</Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {domain.dns && (
                        <>
                          <TableRow>
                            <TableCell sx={{ color: '#fff' }}><PublicIcon sx={{ color: '#56CCF2' }} /> A Records</TableCell>
                            <TableCell sx={{ color: '#fff' }}>{domain.dns.a?.join(', ') || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ color: '#fff' }}><PublicIcon sx={{ color: '#56CCF2' }} /> AAAA Records</TableCell>
                            <TableCell sx={{ color: '#fff' }}>{domain.dns.aaaa?.join(', ') || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ color: '#fff' }}><EmailIcon sx={{ color: '#56CCF2' }} /> MX Records</TableCell>
                            <TableCell sx={{ color: '#fff' }}>{domain.dns.mx?.join(', ') || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ color: '#fff' }}><LanguageIcon sx={{ color: '#56CCF2' }} /> NS Records</TableCell>
                            <TableCell sx={{ color: '#fff' }}>{domain.dns.ns?.join(', ') || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ color: '#fff' }}><TextFieldsIcon sx={{ color: '#56CCF2' }} /> TXT Records</TableCell>
                            <TableCell sx={{ color: '#fff' }}>{domain.dns.txt?.join(', ') || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ color: '#fff' }}><SwapHorizIcon sx={{ color: '#56CCF2' }} /> CNAME Records</TableCell>
                            <TableCell sx={{ color: '#fff' }}>{domain.dns.cname?.join(', ') || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ color: '#fff' }}><StorageIcon sx={{ color: '#56CCF2' }} /> SOA Records</TableCell>
                            <TableCell sx={{ color: '#fff' }}>{domain.dns.soa?.join(', ') || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ color: '#fff' }}><PublicIcon sx={{ color: '#56CCF2' }} /> PTR Records</TableCell>
                            <TableCell sx={{ color: '#fff' }}>{domain.dns.ptr?.join(', ') || 'N/A'}</TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* Certificate Information */}
              <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#56CCF2' }}>Certificate Information</Typography>
                <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'auto', backgroundColor: '#1e2133' }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ color: '#fff' }}><strong>Issuer</strong></TableCell>
                        <TableCell sx={{ color: '#fff' }}>{domain.certificate?.issuer || 'N/A'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ color: '#fff' }}><strong>Subject Name</strong></TableCell>
                        <TableCell sx={{ color: '#fff' }}>{domain.certificate?.subject_name || 'N/A'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ color: '#fff' }}><strong>Protocol</strong></TableCell>
                        <TableCell sx={{ color: '#fff' }}>{domain.certificate?.protocol || 'N/A'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ color: '#fff' }}><strong>Valid From</strong></TableCell>
                        <TableCell sx={{ color: '#fff' }}>{domain.certificate?.valid_from_utc || 'N/A'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ color: '#fff' }}><strong>Valid To</strong></TableCell>
                        <TableCell sx={{ color: '#fff' }}>{domain.certificate?.valid_to_utc || 'N/A'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ color: '#fff' }}><strong>Key Exchange</strong></TableCell>
                        <TableCell sx={{ color: '#fff' }}>{domain.certificate?.key_exchange || 'N/A'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ color: '#fff' }}><strong>Key Exchange Group</strong></TableCell>
                        <TableCell sx={{ color: '#fff' }}>{domain.certificate?.key_exchange_group || 'N/A'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ color: '#fff' }}><strong>Signature Algorithm</strong></TableCell>
                        <TableCell sx={{ color: '#fff' }}>{domain.certificate?.signature_algorithm || 'N/A'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ color: '#fff' }}><strong>SAN List</strong></TableCell>
                        <TableCell sx={{ color: '#fff' }}>{domain.certificate?.san_list?.join(', ') || 'N/A'}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))
    ) : (
      <Typography sx={{ color: 'gray', fontSize: '1.1rem' }}>No domain data available</Typography>
    )}
  </Box>
);

export default Domains;
