// CrowdstrikeIntegrationDialog.js
import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const WebamonIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Webamon Integration"
    integrationName="webamon"
  />
);

export default WebamonIntegrationDialog;
