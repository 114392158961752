import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const WhoisXMLIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="WHOIS XML Integration"
    integrationName="whoisxml"
  />
);

export default WhoisXMLIntegrationDialog;
