import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const CrowdstrikeIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Crowdstrike Integration"
    integrationName="crowdstrike"
  />
);

export default CrowdstrikeIntegrationDialog;
