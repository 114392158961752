import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import StorageIcon from '@mui/icons-material/Storage';
import LanguageIcon from '@mui/icons-material/Language';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

// Helper function to convert ISO country code to flag emoji
const countryCodeToFlag = (countryCode) => {
  return countryCode
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt()));
};

const IpComponent = ({ server }) => (
  <Box sx={{ padding: '2rem', backgroundColor: '#2a2d3e', color: '#fff' }}>
    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#56CCF2', marginBottom: '1rem' }}>
      {server.ip || 'N/A'}{' '}
      {server.country?.iso && (
        <span style={{ marginLeft: '0.5rem', fontSize: '1.5rem' }}>
          {countryCodeToFlag(server.country.iso)}
        </span>
      )}
    </Typography>

    <TableContainer component={Paper} sx={{ backgroundColor: '#1e2133', color: '#fff' }}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ color: '#fff' }}><CheckCircleOutlineIcon sx={{ color: '#56CCF2' }} /> Hosting Scripts</TableCell>
            <TableCell sx={{ color: '#fff' }}>
              {server.hosting_scripts ? <CheckCircleOutlineIcon sx={{ color: 'green' }} /> : <CancelIcon sx={{ color: 'red' }} />}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ color: '#fff' }}><StorageIcon sx={{ color: '#56CCF2' }} /> MIME Types</TableCell>
            <TableCell sx={{ color: '#fff' }}>
              {server.mime_type ? server.mime_type.join(', ') : 'N/A'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ color: '#fff' }}><PublicIcon sx={{ color: '#56CCF2' }} /> IP Address</TableCell>
            <TableCell sx={{ color: '#fff' }}>{server.ip || 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ color: '#fff' }}><LanguageIcon sx={{ color: '#56CCF2' }} /> Country</TableCell>
            <TableCell sx={{ color: '#fff' }}>
              {server.country?.name || 'N/A'}{' '}
              {server.country?.iso && (
                <span style={{ marginLeft: '0.5rem', fontSize: '1.5rem' }}>
                  {countryCodeToFlag(server.country.iso)}
                </span>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ color: '#fff' }}><StorageIcon sx={{ color: '#56CCF2' }} /> ASN Info</TableCell>
            <TableCell sx={{ color: '#fff' }}>
              <strong>Number:</strong> {server.asn?.number || 'N/A'} <br />
              <strong>Name:</strong> {server.asn?.name || 'N/A'} <br />
              <strong>Network:</strong> {server.asn?.network || 'N/A'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ color: '#fff' }}><LanguageIcon sx={{ color: '#56CCF2' }} /> Server</TableCell>
            <TableCell sx={{ color: '#fff' }}>{server.server ? server.server.join(', ') : 'N/A'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ color: '#fff' }}>Total Response Size</TableCell>
            <TableCell sx={{ color: '#fff' }}>{server.total_response_size || 'N/A'} bytes</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

export default IpComponent;
