import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const JiraIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Jira Integration"
    integrationName="jira" // This will load fields for `slack` from integrations.json
  />
);


export default JiraIntegrationDialog;
