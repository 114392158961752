// IntelligenceReportDialog.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Divider, Chip, List, ListItem, ListItemText } from '@mui/material';

const IntelligenceReportDialog = ({ open, onClose, report }) => {
  // Dummy data for demonstration
  const threatIndicators = [
    { type: 'IP Address', value: '192.168.1.1', risk: 'High' },
    { type: 'Domain', value: 'malicious-site.com', risk: 'Medium' },
    { type: 'File Hash', value: 'a7f5c6d9...', risk: 'Critical' },
  ];

  const timelineEvents = [
    { time: '10:00 AM', event: 'Suspicious login detected' },
    { time: '10:30 AM', event: 'Malware file uploaded' },
    { time: '11:00 AM', event: 'Data exfiltration attempt' },
  ];

  const associatedEntities = [
    { type: 'Email', value: 'user@example.com' },
    { type: 'IP Address', value: '10.0.0.5' },
    { type: 'Username', value: 'malicious_user' },
  ];

  const recommendations = [
    'Isolate affected systems',
    'Block suspicious IP addresses',
    'Update firewall rules',
    'Alert security team',
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: '#191b2d', // Background color
          color: '#ffffff', // Text color
          borderRadius: '10px',
          width: '1000px', // Fixed width
          maxWidth: 'none',
        },
      }}
    >
      <DialogTitle sx={{ color: '#56CCF2' }}>{report.title}</DialogTitle>
      <DialogContent>
        {/* Overview Section */}
        <Box sx={{ marginBottom: '16px' }}>
          <Typography variant="h6" sx={{ color: '#56CCF2', marginBottom: '8px' }}>
            Overview
          </Typography>
          <Typography variant="body2" sx={{ color: '#bbbbbb' }}>{report.description}</Typography>
          <Box sx={{ display: 'flex', gap: '12px', marginTop: '8px' }}>
            <Chip label="Severity: High" color="error" />
            <Chip label="Category: Malware" color="secondary" />
            <Chip label="Last Updated: 1 hour ago" color="primary" />
          </Box>
        </Box>

        <Divider sx={{ backgroundColor: '#343b6f', marginY: '16px' }} />

        {/* Threat Indicators */}
        <Box sx={{ marginBottom: '16px' }}>
          <Typography variant="h6" sx={{ color: '#56CCF2', marginBottom: '8px' }}>
            Threat Indicators
          </Typography>
          <List>
            {threatIndicators.map((indicator, index) => (
              <ListItem key={index} sx={{ padding: 0 }}>
                <ListItemText
                  primary={`${indicator.type}: ${indicator.value}`}
                  secondary={`Risk Level: ${indicator.risk}`}
                  primaryTypographyProps={{ color: '#ffffff' }}
                  secondaryTypographyProps={{ color: indicator.risk === 'Critical' ? '#ff0000' : '#bbbbbb' }}
                />
              </ListItem>
            ))}
          </List>
        </Box>

        <Divider sx={{ backgroundColor: '#343b6f', marginY: '16px' }} />

        {/* Timeline Section */}
        <Box sx={{ marginBottom: '16px' }}>
          <Typography variant="h6" sx={{ color: '#56CCF2', marginBottom: '8px' }}>
            Timeline
          </Typography>
          {timelineEvents.map((event, index) => (
            <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px 0' }}>
              <Typography sx={{ color: '#56CCF2' }}>{event.time}</Typography>
              <Typography sx={{ color: '#ffffff' }}>{event.event}</Typography>
            </Box>
          ))}
        </Box>

        <Divider sx={{ backgroundColor: '#343b6f', marginY: '16px' }} />

        {/* Associated Entities */}
        <Box sx={{ marginBottom: '16px' }}>
          <Typography variant="h6" sx={{ color: '#56CCF2', marginBottom: '8px' }}>
            Associated Entities
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {associatedEntities.map((entity, index) => (
              <Typography key={index} sx={{ color: '#ffffff' }}>
                {entity.type}: {entity.value}
              </Typography>
            ))}
          </Box>
        </Box>

        <Divider sx={{ backgroundColor: '#343b6f', marginY: '16px' }} />

        {/* Recommendations */}
        <Box>
          <Typography variant="h6" sx={{ color: '#56CCF2', marginBottom: '8px' }}>
            Recommendations
          </Typography>
          <List>
            {recommendations.map((recommendation, index) => (
              <ListItem key={index} sx={{ padding: 0 }}>
                <ListItemText primary={recommendation} primaryTypographyProps={{ color: '#ffffff' }} />
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ color: '#56CCF2' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IntelligenceReportDialog;
