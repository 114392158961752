import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const XSOARIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="XSOAR Integration"
    integrationName="xsoar"
  />
);

export default XSOARIntegrationDialog;
