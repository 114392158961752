import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  InputAdornment,
  Alert,
  Tooltip,
  Select,
  MenuItem,
  styled
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import UpgradeDialog from './UpgradeDialog';
import StringComponent from './StringComponent';
import DomainComponent from './DomainComponent';
import IpComponent from './IpComponent';
import LimitDialog from './LimitDialog';
import SignupForm from './SignupForm'
import Sha256Component from './Sha256Component.js';
import ReportComponent from './ReportComponent.js';
import LoginDialog from './LoginForm'; // Import your login dialog component

import { Security, Visibility, Warning, Fingerprint, BugReport, ReportProblem } from '@mui/icons-material';

import './ScanPage.css';
import SignUp from './SignUp.js';
import Navbar from './Navbar.js';
import { useAuth } from './AuthContext';
import threatIndicators from './threatIndicators.json'; // Import the JSON data


const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: '#2a2d3e',
    color: 'white',
    fontSize: '1rem',
    padding: '16px',
    borderRadius: '8px',
    maxWidth: '250px',
    border: '1px solid #56CCF2',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4)',
  },
  [`& .MuiTooltip-arrow`]: {
    color: '#2a2d3e',
  },
});

const ScanPage = () => {
  const [submission, setSubmission] = useState("");
  const [selectedOption, setSelectedOption] = useState("auto");
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(null);
  const [validationError, setValidationError] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(null);
  const [screenshotData, setScreenshotData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [componentType, setComponentType] = useState(null);
  const [componentData, setComponentData] = useState(null);
  const [limitDialogOpen, setLimitDialogOpen] = useState(false);
  const [isPerformingScan, setIsPerformingScan] = useState(false);
  const [openSignUpDialog, setOpenSignUpDialog] = useState(false);
  const [message, setMessage] = useState('');
  const floatingContainerRef = useRef(null);
  const [floatingObjects, setFloatingObjects] = useState([])
  const [showLoginDialog, setShowLoginDialog] = useState(false); // State to control login dialog visibility
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false); // State for UpgradeDialog

const getSubdomain = () => {
  // Check if the user is authenticated by looking for a token in local storage
  const token = localStorage.getItem("accessToken");  // Replace with your actual storage key
  if (token) {
    return { subdomain: "community", token };  // Return token along with subdomain
  } else {
    return { subdomain: "public", token: null };  // No token, just public subdomain
  }
};


const { subdomain, token } = getSubdomain();

const getIndicatorConfig = (type) => {
    switch (type) {
      case "firstSeenIP":
        return { icon: <Fingerprint />, colorClass: "floating-ip", sizeClass: "floating-small" };
      case "newScanSubmitted":
        return { icon: <Security />, colorClass: "floating-scan", sizeClass: "floating-medium" };
      case "threatActorActivity":
        return { icon: <Warning />, colorClass: "floating-threat", sizeClass: "floating-large" };
      case "malwareDetected":
        return { icon: <BugReport />, colorClass: "floating-malware", sizeClass: "floating-medium" };
      case "unauthorizedAccess":
        return { icon: <ReportProblem />, colorClass: "floating-access", sizeClass: "floating-large" };
      default:
        return { icon: <Visibility />, colorClass: "floating-generic", sizeClass: "floating-small" };
    }
  };

  // Define the restricted area based on the red box coordinates
  const restrictedArea = {
    top: 1300, // Adjust based on your red box's position
    left: window.innerWidth * 0.15, // 15% from the left
    width: window.innerWidth * 0.70, // 70% of the window width
    height: 1200, // Adjust based on your red box height
  };

  // Function to generate random position outside the restricted area
  const generateRandomStyle = () => {
    let randomTop, randomLeft;

    do {
      randomTop = Math.random() * (window.innerHeight - 100); // Random top position
      randomLeft = Math.random() * (window.innerWidth - 100); // Random left position
    } while (
      randomTop > restrictedArea.top &&
      randomTop < restrictedArea.top + restrictedArea.height &&
      randomLeft > restrictedArea.left &&
      randomLeft < restrictedArea.left + restrictedArea.width
    );

    return {
      top: `${randomTop}px`,
      left: `${randomLeft}px`,
      opacity: 0,
      animationDelay: `${Math.random() * 5}s`,
      animationDuration: `${12 + Math.random() * 8}s`,
    };
  };

  useEffect(() => {
    const generateFloatingObjects = () => {
      const objects = threatIndicators.slice(0, 10).map((indicator, index) => {
        const config = getIndicatorConfig(indicator.indicator);
        return {
          ...config,
          id: index,
          style: generateRandomStyle(),
          data: indicator || {},
        };
      });
      setFloatingObjects(objects);
      console.log("Generated floating objects:", objects); // Debug log
    };

    generateFloatingObjects();



    return
  }, []);



  const handleCloseLimitDialog = () => {
    setLimitDialogOpen(false);
  };

  const fetchScreenshot = async (report_id) => {
    try {
    const screenshotUrl = `https://${subdomain}.webamon.co.uk/screenshot/${report_id}`;
    const headers = token ? { Authorization: `Bearer ${token}` } : {};  // Add Bearer token if available
    const screenshotResponse = await axios.get(screenshotUrl, { responseType: 'json', headers });


        if (screenshotResponse.status === 401 || screenshotResponse.status === 403) {
          // If 401 or 403, show the login dialog
          setShowLoginDialog(true);
          setLoading(false);
          return;
        } else if (screenshotResponse.status === 429) {
                   setShowUpgradeDialog(true);
                   setLoading(false);
                   return;
                 }



      if (screenshotResponse.data?.screenshot) {
        const imageUrl = `data:image/png;base64,${screenshotResponse.data.screenshot}`;
        setScreenshotData(imageUrl);
        console.log('screenshotdata', screenshotData)
      } else {
        console.error("Screenshot data not found.");
        setScreenshotData(null);
      }
    } catch (error) {
      console.error("Error fetching screenshot:", error);
      setScreenshotData(null);
    }
  };

  const fetchReport = async (report_id, attempts = 0) => {
    const maxAttempts = 20;
    const retryDelay = 3000;

    if (attempts === 0) {
      setLoading(true);
      setIsPerformingScan(true);
    }

    try {
    const reportUrl = `https://${subdomain}.webamon.co.uk/results/${report_id}`;  // Dynamic subdomain
    const headers = token ? { Authorization: `Bearer ${token}` } : {};  // Add Bearer token if available
    const reportResponse = await axios.get(reportUrl, { headers });

        if (reportResponse.status === 401 || reportResponse.status === 403) {
          // If 401 or 403, show the login dialog
          setShowLoginDialog(true);
          setLoading(false);
          return;
        } else if (reportResponse.status === 429) {
                    setShowUpgradeDialog(true);
                    setLoading(false);
                    return;
                  }



      if (reportResponse.status === 200 && reportResponse.data) {
        setComponentType("report");
        setComponentData(reportResponse.data);
        fetchScreenshot(report_id);

        setIsPerformingScan(false);
        setLoading(false);
        setOpenDialog(true);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404 && attempts < maxAttempts) {
          await new Promise(resolve => setTimeout(resolve, retryDelay));
          return fetchReport(report_id, attempts + 1);
        } else if (error.response.status === 401) {
          setLimitDialogOpen(true);
          setLoading(false);
          setIsPerformingScan(false);
        } else if (error.response.status === 500) {
          setErrorMessage(error.response.data.error || "Server error occurred");
          setError(true);
          setLoading(false);
          setIsPerformingScan(false);
        } else {
          setErrorMessage("Failed to retrieve report after multiple attempts.");
          setError(true);
          setLoading(false);
          setIsPerformingScan(false);
        }
      } else {
        setErrorMessage("Network error or unexpected issue occurred.");
        setError(true);
        setLoading(false);
        setIsPerformingScan(false);
      }
    }
  };

  const handleDomainChange = (event) => {
    setSubmission(event.target.value);
  };

  const handleClearInput = () => {
    setSubmission("");
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

const handleSubmit = async (event) => {
  setReport("");
  setError(false);
  event.preventDefault();
  setValidationError(false);
  setLoading(true);
  setIsPerformingScan(false);

  setComponentData(null);
  setComponentType(null);

  const endpoint = `https://${subdomain}.webamon.co.uk/hunt`;
  const requestData = {
    'hunt_submission': submission,
    'search_option': selectedOption
  };

  try {
    const headers = token ? { Authorization: `Bearer ${token}` } : {};  // Add Bearer token if available
    const response = await axios.post(endpoint, requestData, { headers });

    if (response.status === 401 || response.status === 403) {
      // If 401 or 403, show the login dialog
      setShowLoginDialog(true);
      setLoading(false);
      return;
    }

    if (response.status === 429) {
      setShowUpgradeDialog(true); // Show UpgradeDialog if status is 429
      setLoading(false);
      return;
    }

    if (response.status === 200) {
      const { type, results } = response.data;

      setComponentType(type);
      setComponentData(results);

      if (type === "report") {
        await fetchScreenshot(submission);
      }
      setOpenDialog(true);
    }
  } catch (error) {
    // Existing error handling code
    if (error.response && error.response.status === 400) {
      const errorData = error.response.data;

      if (errorData.scan === true) {
        setIsPerformingScan(true);
        const newEndpoint = `https://${subdomain}.webamon.co.uk/scan`;
        const newRequestData = { 'submission_url': submission };

        try {
          const headers = token ? { Authorization: `Bearer ${token}` } : {};  // Add Bearer token if available
          const newResponse = await axios.post(newEndpoint, newRequestData, { headers });

          if (newResponse.status === 401 || newResponse.status === 403) {
            setShowLoginDialog(true);
            setLoading(false);
            return;
          } else if (newResponse.status === 429) {
            setShowUpgradeDialog(true); // Show UpgradeDialog if status is 429
            setLoading(false);
            return;
          }

          if (newResponse.status === 200) {
            setIsPerformingScan(true);
            setLoading(true);
            fetchReport(newResponse.data.report_id);
          } else {
            console.error("Failed to post to the new endpoint:", newResponse.data);
          }
        } catch (newError) {
          if (newError.response && newError.response.status === 401) {
            setLimitDialogOpen(true);
          } else {
            console.error("Error posting to new endpoint:", newError);
          }
        }
      } else if (errorData.scan === false) {
        setErrorMessage("No results found");
        setError(true);
      } else {
        setErrorMessage("400 Bad Request: No valid scan key found");
      }
    } else if (error.response && error.response.status === 429) {
      setShowUpgradeDialog(true);
      setLoading(false);
    } else if (error.response && error.response.status === 401) {
      setLimitDialogOpen(true);
    } else {
      setErrorMessage(error.response?.data?.error || error.message || "An error occurred");
    }
  } finally {
    if (!isPerformingScan) {
      setLoading(false);
    }
  }
};



  const handleCloseDialog = () => {
    setOpenDialog(false);
    setComponentData(null);
    setComponentType(null);
    setScreenshotData(null);
  };

  const setApiKey = useAuth();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        minHeight: "100vh",
        width: "100%",
        paddingTop: '0rem', // Adjusted padding to move content higher
      }}
    >

              {showLoginDialog && <LoginDialog open={showLoginDialog} onClose={() => setShowLoginDialog(false)} />}
                    {showUpgradeDialog && <UpgradeDialog open={showUpgradeDialog} onClose={() => setShowUpgradeDialog(false)} />}


      <Navbar />
            {floatingObjects.map((obj) => (
              <CustomTooltip
                key={obj.id}
                title={
                  obj.data ? (
                    <Box sx={{ color: 'white', textAlign: 'left' }}>
                      <strong>Type:</strong> {obj.data.indicator || "Unknown"}<br />
                      {Object.keys(obj.data).map(key => (
                        key !== "indicator" && (
                          <div key={key}>
                            <strong>{key}:</strong> {obj.data[key]}
                          </div>
                        )
                      ))}
                    </Box>
                  ) : (
                    "No data available"
                  )
                }
                placement="top"
                arrow
              >
                <Box
                  className={`floating-object ${obj.colorClass} ${obj.sizeClass}`}
                  style={obj.style}
                >
                  {obj.icon}
                </Box>
              </CustomTooltip>
            ))}
      <div className="navbar-logo">
        <img
          src="/assets/logo.png"
          alt="Webamon Logo"
          className="logo-image"
          style={{ paddingBottom: '1.3rem' }}
        />
      </div>

      <form onSubmit={handleSubmit} style={{ width: "100%", maxWidth: "50%" }}>
        <Box sx={{ width: '100%', marginTop: "10px" }}>
          <TextField
            value={submission}
            placeholder="Your Intelligence Journey Starts Here..."
            onChange={handleDomainChange}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Clear">
                    <ClearIcon
                      onClick={handleClearInput}
                      sx={{ cursor: 'pointer', color: 'white', marginRight: '8px' }}
                    />
                  </Tooltip>
                  <Select
                    value={selectedOption}
                    onChange={handleOptionChange}
                    variant="outlined"
                    size="small"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          backgroundColor: '#2a2d3e',
                          color: 'white',
                          width: 300,
                        },
                      },
                    }}
                    sx={{
                      color: 'white',
                      backgroundColor: '#2a2d3e',
                      borderRadius: '4px',
                      width: 200,
                      '& .MuiSelect-select': {
                        padding: '8px',
                        fontSize: '0.95rem',
                      },
                    }}
                  >
                    <MenuItem value="auto" sx={{ color: 'white', fontWeight: 'bold' }}>Auto</MenuItem>

                    <MenuItem value="scan" sx={{ color: 'white' }}>Scan - Perform Webamon scan</MenuItem>
                    <MenuItem value="ip" sx={{ color: 'white' }}>IP - Search IP data</MenuItem>
                    <MenuItem value="sha256" sx={{ color: 'white' }}>SHA256 - Search Within Resources</MenuItem>
                    <MenuItem value="url" sx={{ color: 'white' }}>URL - Search URL data</MenuItem>
                    <MenuItem value="dom" sx={{ color: 'white' }}>DOM - Search within DOMs</MenuItem>
                    <MenuItem value="resource" sx={{ color: 'white' }}>Resource - Search resources</MenuItem>
                    <MenuItem value="domain" sx={{ color: 'white' }}>Domain - Search Domain data</MenuItem>
                    <MenuItem value="title" sx={{ color: 'white' }}>Website Titles - Search Titles</MenuItem>
                    <MenuItem value="server" sx={{ color: 'white' }}>Server - Name of server</MenuItem>
                    <MenuItem value="report" sx={{ color: 'white' }}>Report ID - Fetch scan result</MenuItem>
                    <MenuItem value="mitre" sx={{ color: 'white' }}>Mitre ID - Map to Mitre TTPs</MenuItem>
                    <MenuItem value="cve" sx={{ color: 'white' }}>CVE ID - Map to CVE-IDs</MenuItem>
                    <MenuItem value="social" sx={{ color: 'white' }}>Socials - Search across socials</MenuItem>
                    <MenuItem value="san" sx={{ color: 'white' }}>SAN Lists - SSL SAN Lists</MenuItem>
                    <MenuItem value="tag" sx={{ color: 'white' }}>TAG - Return Intel Matching Tag</MenuItem>
                    <MenuItem value="sweep" sx={{ color: 'white', fontWeight: 'bold' }}>Local Sweep</MenuItem>
                  </Select>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { fontSize: '1.5rem', color: 'white' }
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#2a2d3e',
                borderRadius: '8px',
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },
              '& .MuiInputBase-input': {
                color: 'white',
                padding: '20px',
                fontSize: '1.6rem',
                '&::placeholder': {
                  color: 'white',
                  opacity: 1,
                },
              },
            }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '16px', marginTop: '30px' }}>
            <Button
              type="submit"
              variant="contained"
              disabled={loading || isPerformingScan}
              sx={{
                width: '320px',
                height: '70px',
                backgroundImage: loading || isPerformingScan ? 'linear-gradient(135deg, #ff6b6b, #ff8a5c)' : 'linear-gradient(135deg, #4a90e2, #56CCF2)',
                color: loading || isPerformingScan ? 'rgba(255, 255, 255, 0.8)' : '#ffffff',
                padding: '12px 24px',
                fontWeight: 'bold',
                fontSize: '1.3rem',
                textTransform: 'uppercase',
                borderRadius: '8px',
                '&:hover': {
                  backgroundImage: loading || isPerformingScan ? 'linear-gradient(135deg, #ff6b6b, #ff8a5c)' : 'linear-gradient(135deg, #6d28d9, #ec4899)',
                  boxShadow: loading || isPerformingScan ? '0px 4px 10px rgba(255, 107, 107, 0.5)' : '0px 4px 10px rgba(74, 144, 226, 0.5)',
                },
              }}
            >
              {isPerformingScan ? (
                <>
                  <CircularProgress size={24} color="inherit" sx={{ marginRight: '8px' }} />
                  Performing scan...
                </>
              ) : loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "SEARCH"
              )}
            </Button>

            <Button
              variant="contained"
              disabled={loading || isPerformingScan}
              sx={{
                width: '320px',
                height: '70px',
                backgroundImage: 'linear-gradient(135deg, #4a90e2, #56CCF2)',
                color: '#ffffff',
                padding: '12px 24px',
                fontWeight: 'bold',
                fontSize: '1.3rem',
                textTransform: 'uppercase',
                borderRadius: '8px',
                '&:hover': {
                  backgroundImage: 'linear-gradient(135deg, #9b2226, #fbb6ce)',
                  boxShadow: '0px 4px 10px rgba(155, 34, 38, 0.5)',
                },
              }}
            >
              I’m Feeling Defensive
            </Button>
          </Box>
        </Box>

        {error && (
          <Box sx={{ marginTop: "1rem" }}>
            <Alert severity="error">{errorMessage}</Alert>
          </Box>
        )}
      </form>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "#2a2d3e",
            color: "white",
          },
        }}
      >
          {componentData && (
            <>
              {componentType === "string" && <StringComponent results={componentData} />}
              {componentType === "domain" && <DomainComponent domain={componentData.results} />}
              {componentType === "ip" && <IpComponent server={componentData.results} />}
              {componentType === "sha256" && <Sha256Component results={componentData} />}
              {componentType === "report" && (
                <ReportComponent results={componentData} screenshot={screenshotData} onClose={handleCloseDialog} />
              )}
            </>
          )}
      </Dialog>

      <SignupForm open={limitDialogOpen} onClose={handleCloseLimitDialog} />
    </Box>
  );
};

export default ScanPage;
