// VulnerabilityScans.js
import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const scanResults = [
  { id: '001', severity: 'Critical', issue: 'SQL Injection', status: 'Unresolved' },
  { id: '002', severity: 'High', issue: 'Cross-Site Scripting', status: 'Unresolved' },
  { id: '003', severity: 'Medium', issue: 'Open Redirect', status: 'Resolved' },
];

const VulnerabilityScans = () => {
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" sx={{ color: '#56CCF2', marginBottom: 2 }}>
        Vulnerability Scan Results
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Severity</TableCell>
              <TableCell>Issue</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scanResults.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.severity}</TableCell>
                <TableCell>{row.issue}</TableCell>
                <TableCell>{row.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VulnerabilityScans;
