import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Typography,
  List,
  CircularProgress,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import close icon
import axios from 'axios'; // Ensure axios is imported
import LoginDialog from './LoginForm'; // Import your login dialog component
import UpgradeDialog from './UpgradeDialog';

const countryCodeToFlag = (countryCode) => {
  return countryCode
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt()));
};

const ScansDialog = ({ open, onClose }) => {
  const [scanFilterText, setScanFilterText] = useState('');
  const [filteredScans, setFilteredScans] = useState([]);
  const [scans, setScans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const hasFetchedData = useRef(false);

  const [screenshotLoading, setScreenshotLoading] = useState({});
  const [screenshots, setScreenshots] = useState({});
  const [showLoginDialog, setShowLoginDialog] = useState(false); // State to control login dialog visibility
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false); // State for UpgradeDialog

      const accessToken = localStorage.getItem('accessToken');


  // Fetch data from the API when the component is mounted
 useEffect(() => {
   const fetchScansData = async () => {
     try {
       const response = await axios.get('https://community.webamon.co.uk/results', {
         headers: {
           Authorization: `Bearer ${accessToken}`, // Authorization header added
         },
       });

       if (response.status === 401 || response.status === 403) {
         setShowLoginDialog(true);
         setLoading(false);
         return;
       }

                 if (response.status === 429) {
                           // If 429, show the upgrade dialog
                           setShowUpgradeDialog(true);
                           setLoading(false);
                           return;
                         }

       const scanResults = Object.values(response.data.reports) || [];

       // Sort the scanResults by meta.submission_utc in descending order
       const sortedResults = scanResults.sort((a, b) => {
         const dateA = new Date(a.meta?.submission_utc);
         const dateB = new Date(b.meta?.submission_utc);
         return dateB - dateA;
       });

       setScans(sortedResults);
       setFilteredScans(sortedResults);
       setLoading(false);
       hasFetchedData.current = true;
     } catch (err) {
       // Error handling for non-2xx responses
       if (err.response) {
         if (err.response.status === 429) {
           setShowUpgradeDialog(true);
         } else if (err.response.status === 401 || err.response.status === 403) {
           setShowLoginDialog(true);
         } else {
           setError('Failed to load data');
         }
       } else {
         setError('Failed to load data');
       }
       setLoading(false);
     }
   };

   if (open && !hasFetchedData.current) {
     fetchScansData();
   }
 }, [open, accessToken]);


  // Filter scans based on search input
  useEffect(() => {
    if (scanFilterText.trim() === '') {
      setFilteredScans(scans);
    } else {
      const filtered = scans.filter((scan) =>
        scan?.meta?.submission_url?.toLowerCase().includes(scanFilterText.toLowerCase())
      );
      setFilteredScans(filtered);
    }
  }, [scanFilterText, scans]);

  // Handle fetching screenshot
  const handleShowScreenshot = async (reportId) => {
    if (!screenshots[reportId] && !screenshotLoading[reportId]) {
      try {
        setScreenshotLoading((prev) => ({ ...prev, [reportId]: true })); // Set loading state
        const response = await axios.get(`https://community.webamon.co.uk/screenshot/${reportId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Authorization header added
          },
        });


                if (response.status === 401 || response.status === 403) {
                  // If 401 or 403, show the login dialog
                  setShowLoginDialog(true);
                  setLoading(false);
                  return;
                }


                   if (response.status === 429) {
                     setShowUpgradeDialog(true); // Show UpgradeDialog if status is 429
                     setLoading(false);
                     return;
                   }

        setScreenshots((prevScreenshots) => ({
          ...prevScreenshots,
          [reportId]: `data:image/png;base64,${response.data.screenshot}`, // Store the base64 image with appropriate data URL format
        }));
        setScreenshotLoading((prev) => ({ ...prev, [reportId]: false })); // Set loading to false
      } catch (err) {
        console.error('Error fetching screenshot:', err);

              if (err.response) {
                if (err.response.status === 401 || err.response.status === 403) {
                  // If 401 or 403, show the login dialog
                  setShowLoginDialog(true);
                } else if (err.response.status === 429) {
                  // Show UpgradeDialog if status is 429
                  setShowUpgradeDialog(true);
                }
              }


        setScreenshots((prevScreenshots) => ({
          ...prevScreenshots,
          [reportId]: null, // Store null if there's an error
        }));
        setScreenshotLoading((prev) => ({ ...prev, [reportId]: false })); // Set loading to false
      }
    }
  };


  // Handle closing the screenshot (reverting to placeholder)
  const handleCloseScreenshot = (reportId) => {
    setScreenshots((prevScreenshots) => ({
      ...prevScreenshots,
      [reportId]: null, // Remove the screenshot
    }));
  };

  // Clear all values and close the dialog
  const handleCloseDialog = () => {
    setScanFilterText('');
    setFilteredScans([]);
    setScans([]);
    setLoading(true);
    setError(null);
    setScreenshots({});
    setScreenshotLoading({});
    hasFetchedData.current = false; // Reset the fetched state
    onClose(); // Close the dialog
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      maxWidth="lg" // Adjust the max width to make the dialog wider
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: '#1e2133',
          color: '#ffffff',
          padding: '30px',
          borderRadius: '16px',
          overflow: 'hidden',
          width: '95vw', // Set a wider dialog width
          height: '90vh', // Set the height to be taller
        },
      }}
    >

              {showLoginDialog && <LoginDialog open={showLoginDialog} onClose={() => setShowLoginDialog(false)} />}
               {showUpgradeDialog && <UpgradeDialog open={showUpgradeDialog} onClose={() => setShowUpgradeDialog(false)} />}

      <DialogTitle sx={{ fontSize: '2rem', fontWeight: 'bold', color: '#ffffff', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Previous Scans
        {/* Close Icon */}
        <IconButton
          onClick={handleCloseDialog}
          sx={{ color: '#ff6b6b' }} // Red color for the close button
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ overflowX: 'hidden', padding: 0 }}>
        <TextField
          placeholder="Search scans..."
          variant="outlined"
          fullWidth
          value={scanFilterText}
          onChange={(e) => setScanFilterText(e.target.value)}
          sx={{
            marginBottom: '20px',
            fontSize: '1.2rem',
            backgroundColor: '#2b2f44',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#56CCF2',
              },
              '&:hover fieldset': {
                borderColor: '#4a90e2',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#4a90e2',
              },
            },
            input: { color: '#ffffff', fontSize: '1.1rem' },
          }}
        />

        {loading ? (
          <Typography sx={{ color: '#ffffff', textAlign: 'center' }}>Loading...</Typography>
        ) : error ? (
          <Typography sx={{ color: '#ff6b6b', textAlign: 'center' }}>{error}</Typography>
        ) : (
          <List sx={{ maxHeight: '70vh', overflowY: 'auto', paddingRight: '10px' }}>
            {filteredScans.map((scan, index) => (
              <Box
                key={index}
                sx={{
                  marginBottom: '20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: '#2b2f44',
                  padding: '20px',
                  borderRadius: '12px',
                }}
              >
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))',
                    gap: '20px',
                    marginRight: '15px',
                    width: '75%', // Ensures space for the placeholder box
                  }}
                >
                  <Box>
                    <Typography variant="body2" sx={{ color: '#bbb', fontSize: '1rem' }}>Resolved Domain:</Typography>
                    <Typography sx={{ fontSize: '1.1rem' }}>{scan?.resolved_domain || 'N/A'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" sx={{ color: '#bbb', fontSize: '1rem' }}>Submission Time:</Typography>
                    <Typography sx={{ fontSize: '1.1rem' }}>{scan?.meta?.submission_utc || 'N/A'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" sx={{ color: '#bbb', fontSize: '1rem' }}>Script Count:</Typography>
                    <Typography sx={{ fontSize: '1.1rem' }}>{scan?.meta?.script_count || 'N/A'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" sx={{ color: '#bbb', fontSize: '1rem' }}>Request Count:</Typography>
                    <Typography sx={{ fontSize: '1.1rem' }}>{scan?.meta?.request_count || 'N/A'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" sx={{ color: '#bbb', fontSize: '1rem' }}>Domain Count:</Typography>
                    <Typography sx={{ fontSize: '1.1rem' }}>{scan?.meta?.domain_count || 'N/A'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" sx={{ color: '#bbb', fontSize: '1rem' }}>Tags:</Typography>
                    <Typography sx={{ fontSize: '1.1rem' }}>{scan?.tag || 'N/A'}</Typography>
                  </Box>
                  {/* Conditionally display the new fields */}
                  <Box>
                    <Typography variant="body2" sx={{ color: '#bbb', fontSize: '1rem' }}>CVE IDs:</Typography>
                    <Typography sx={{ fontSize: '1.1rem' }}>{scan?.cve_ids?.join(', ') || 'N/A'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" sx={{ color: '#bbb', fontSize: '1rem' }}>MITRE IDs:</Typography>
                    <Typography sx={{ fontSize: '1.1rem' }}>{scan?.mitre_ids?.join(', ') || 'N/A'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" sx={{ color: '#bbb', fontSize: '1rem' }}>Threat Actors:</Typography>
                    <Typography sx={{ fontSize: '1.1rem' }}>{scan?.threat_actors?.join(', ') || 'N/A'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" sx={{ color: '#bbb', fontSize: '1rem' }}>Detections:</Typography>
                    <Typography sx={{ fontSize: '1.1rem' }}>{scan?.detections?.join(', ') || 'N/A'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" sx={{ color: '#bbb', fontSize: '1rem' }}>Technology:</Typography>
                    <Typography sx={{ fontSize: '1.1rem' }}>{scan?.technology?.join(', ') || 'N/A'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" sx={{ color: '#bbb', fontSize: '1rem' }}>Countries:</Typography>
                    <Box sx={{ fontSize: '1.5rem', display: 'flex', gap: '8px' }}>
                      {scan?.countries?.length
                        ? scan.countries.map((code) => <span key={code}>{countryCodeToFlag(code)}</span>)
                        : 'N/A'}
                    </Box>
                  </Box>
                </Box>

                {/* Screenshot Placeholder Box */}
                <Box
                  sx={{
                    width: '25%',
                    backgroundColor: '#1e2133',
                    border: '1px solid #56CCF2',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#bbb',
                    textAlign: 'center',
                    cursor: 'pointer',
                    position: 'relative',
                  }}
                  onClick={() => handleShowScreenshot(scan.report_id)}
                >
                  {screenshotLoading[scan.report_id] ? (
                    <CircularProgress sx={{ color: '#56CCF2' }} />
                  ) : screenshots[scan.report_id] ? (
                    <>
                      <img
                        src={screenshots[scan.report_id]}
                        alt="Screenshot"
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          borderRadius: '8px',
                        }}
                      />
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCloseScreenshot(scan.report_id);
                        }}
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          color: '#ff6b6b', // Bright red close icon
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          },
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </>
                  ) : (
                    <Typography>Show Screenshot</Typography>
                  )}
                </Box>
              </Box>
            ))}
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ScansDialog;
