import React, { useState, useEffect } from 'react';
import { Dialog, Box, Typography, Button, Divider, IconButton, TextField, Collapse } from '@mui/material';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DeleteIcon from '@mui/icons-material/Delete';
import integrationsData from './integrations.json';  // Import integrations JSON file
import functionsData from './functions.json';  // Import functions JSON file

const ITEM_TYPE = 'STEP';

const DragItem = ({ item }) => {
  const [, drag] = useDrag(() => ({
    type: ITEM_TYPE,
    item,
  }));

  return (
    <Box
      ref={drag}
      sx={{
        padding: '8px',
        marginBottom: '8px',
        backgroundColor:
          item.type === 'action' ? '#4a90e2' :
          item.type === 'code' ? '#8e44ad' :
          item.type === 'integration' ? '#00aaff' :
          '#f0ad4e',
        color: '#ffffff',
        borderRadius: '4px',
        fontSize: '0.9rem',
        cursor: 'grab',
      }}
    >
      {item.name}
    </Box>
  );
};

const DropArea = ({ steps, setSteps, setSelectedIntegration }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ITEM_TYPE,
    drop: (item) => {
      setSteps((prev) => {
        const newSteps = [...prev, { ...item, id: Math.random() }];
        // Set the selected integration's dummy data if the item is an integration action
        if (item.type === 'integration') {
          setSelectedIntegration(item);
        }
        return newSteps;
      });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <Box
      ref={drop}
      sx={{
        minHeight: '300px',
        padding: '16px',
        backgroundColor: isOver ? '#2b2f44' : '#1f2235',
        borderRadius: '8px',
        overflowY: 'auto',
      }}
    >
      {steps.map((step, index) => (
        <FlowStep key={index} step={step} setSteps={setSteps} steps={steps} index={index} />
      ))}
      {steps.length === 0 && <Typography color="#bbbbbb">Drag and drop items here to build your flow.</Typography>}
    </Box>
  );
};

const FlowStep = ({ step, setSteps, steps, index }) => {
  const handleRemove = () => {
    setSteps(steps.filter((_, i) => i !== index));
  };

  const handleConfigChange = (field, value) => {
    const newSteps = [...steps];
    newSteps[index] = { ...newSteps[index], config: { ...newSteps[index].config, [field]: value } };
    setSteps(newSteps);
  };

  return (
    <Box sx={{ padding: '12px', marginBottom: '8px', backgroundColor: '#343b6f', borderRadius: '8px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ color: '#ffffff' }}>{step.name}</Typography>
        <IconButton size="small" onClick={handleRemove}>
          <DeleteIcon sx={{ color: '#f77' }} />
        </IconButton>
      </Box>
      {step.configFields.map((field, idx) => (
        <TextField
          key={idx}
          label={field}
          fullWidth
          margin="normal"
          size="small"
          onChange={(e) => handleConfigChange(field, e.target.value)}
          value={step.config ? step.config[field] : ''}
          InputLabelProps={{ style: { color: '#ffffff' } }}
          InputProps={{
            style: { color: '#ffffff' },
            sx: {
              '& .MuiOutlinedInput-notchedOutline': { borderColor: '#56CCF2' },
              '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#4a90e2' },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#4a90e2' },
            },
          }}
        />
      ))}
    </Box>
  );
};

const NewFlowDialog = ({ open, onClose }) => {
  const [steps, setSteps] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const [functions, setFunctions] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState(null);

  // State to control expanded integration types
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    setIntegrations(integrationsData.integrations);
    setFunctions(functionsData.functions);
  }, []);

  const handleSaveFlow = () => {
    console.log('Flow saved:', steps);
    onClose();
  };

  // Group integrations by integrationType
  const groupedIntegrations = integrations.reduce((acc, integration) => {
    const { integrationType } = integration; // Access the new integration type
    if (!acc[integrationType]) {
      acc[integrationType] = [];
    }
    acc[integrationType].push(integration);
    return acc;
  }, {});

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: '#191b2d',
          color: '#ffffff',
          width: '1000px',
          maxWidth: 'none',
          padding: '20px',
        },
      }}
    >
      <Typography variant="h6" sx={{ color: '#56CCF2', marginBottom: '16px' }}>
        Create New Automation Flow
      </Typography>

      <DndProvider backend={HTML5Backend}>
        <Box sx={{ display: 'flex', gap: '16px' }}>
          {/* Function & Integration Sidebar */}
          <Box
            sx={{
              width: '200px',
              padding: '10px',
              backgroundColor: '#2b2f44',
              borderRadius: '8px',
              maxHeight: '500px',
              overflowY: 'auto',
            }}
          >
            <Typography variant="body1" sx={{ color: '#56CCF2', marginBottom: '8px' }}>
              Available Functions
            </Typography>
            {functions.map((func) => (
              <DragItem key={func.id} item={func} />
            ))}

            {/* Grouping Integrations by Integration Type */}
            {Object.entries(groupedIntegrations).map(([type, integrationsGroup]) => (
              <Box key={type}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: '#56CCF2', margin: '16px 0 8px', cursor: 'pointer' }}
                  onClick={() => setExpanded((prev) => ({ ...prev, [type]: !prev[type] }))}
                >
                  {type}
                </Typography>
                <Collapse in={expanded[type]}>
                  {integrationsGroup.map((integration) => (
                    <Box key={integration.name}>
                      <Typography variant="subtitle2" sx={{ color: '#bbbbbb', margin: '8px 0 4px' }}>
                        {integration.name}
                      </Typography>
                      {integration.actions.map((action) => (
                        <DragItem key={action.id} item={action} />
                      ))}
                    </Box>
                  ))}
                </Collapse>
              </Box>
            ))}
          </Box>

          {/* Flow Drop Area */}
          <DropArea steps={steps} setSteps={setSteps} setSelectedIntegration={setSelectedIntegration} />

          {/* Conditional Rendering of Selected Integration's Dummy Data */}

{selectedIntegration && (
  <Box sx={{ width: '250px', padding: '10px', backgroundColor: '#2b2f44', borderRadius: '8px' }}>
    <Typography variant="body1" sx={{ color: '#56CCF2', marginBottom: '8px' }}>
      Integration Details
    </Typography>
    <Typography variant="subtitle2" sx={{ color: '#bbbbbb', margin: '8px 0 4px' }}>
      {selectedIntegration.name}
    </Typography>
    <Typography variant="body2" sx={{ color: '#ffffff', marginBottom: '4px' }}>
      {selectedIntegration.dummyData?.exampleUsage || "No example usage available."}
    </Typography>
    <Typography variant="body2" sx={{ color: '#ffffff' }}>
      {selectedIntegration.dummyData?.configurationGuide || "No configuration guide available."}
    </Typography>
  </Box>
)}

        </Box>
      </DndProvider>

      <Divider sx={{ backgroundColor: '#343b6f', margin: '20px 0' }} />

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onClose} sx={{ color: '#ffffff' }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSaveFlow}
          sx={{
            backgroundColor: '#4a90e2',
            color: '#ffffff',
            '&:hover': { backgroundColor: '#56CCF2' },
          }}
        >
          Save Flow
        </Button>
      </Box>
    </Dialog>
  );
};

export default NewFlowDialog;
