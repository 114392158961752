import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const ProofpointIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Proofpoint Integration"
    integrationName="proofpoint"
  />
);


export default ProofpointIntegrationDialog;
