import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const VirustotalIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Virustotal Integration"
    integrationName="virustotal"
  />
);

export default VirustotalIntegrationDialog;
