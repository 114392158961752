import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const NessusIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Nessus Integration"
    integrationName="nessus"
  />
);


export default NessusIntegrationDialog;
