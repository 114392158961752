// IncidentReports.js
import React, { useState } from 'react';
import { Box, Typography, Grid, Modal, Button, Chip, Card, CardContent } from '@mui/material';
import AttackPathMap from './AttackPathMap';
import data from './data.json';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoIcon from '@mui/icons-material/Info';

const IncidentReports = () => {
  const [openAttackPath, setOpenAttackPath] = useState(false);
  const [selectedAttackPath, setSelectedAttackPath] = useState(null);
  const { reports, attackPaths } = data;

  const handleOpenAttackPath = (reportId) => {
    setSelectedAttackPath(reportId);
    setOpenAttackPath(true);
  };

  const handleCloseAttackPath = () => {
    setOpenAttackPath(false);
    setSelectedAttackPath(null);
  };

  // Summary Data
  const totalIncidents = reports.length;
  const openIncidents = reports.filter(report => report.status === 'Open').length;
  const criticalIncidents = reports.filter(report => report.severity === 'Critical').length;

  return (
    <Box sx={{ padding: 4, maxWidth: '95vw', margin: '0 auto', borderRadius: '12px', backgroundColor: '#1f2233' }}>

      {/* Summary Section */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 4 }}>
        <Typography variant="h4" sx={{ color: '#56CCF2', fontWeight: 'bold' }}>Recent Incident Reports</Typography>
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant="h6" sx={{ color: '#ffffff' }}>Total Incidents: {totalIncidents}</Typography>
          <Typography variant="h6" sx={{ color: '#e57373' }}>Open Incidents: {openIncidents}</Typography>
          <Typography variant="h6" sx={{ color: '#ff1744' }}>Critical Incidents: {criticalIncidents}</Typography>
        </Box>
      </Box>

      {/* Grid Layout for Larger Report Cards */}
      <Grid container spacing={3}>
        {reports.map((report) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={report.id}>
            <Card
              sx={{
                cursor: 'pointer',
                backgroundColor: '#2b2f44',
                '&:hover': { backgroundColor: '#343b6f' },
                borderRadius: '8px',
                padding: '24px',
                minHeight: '300px', // Increased minimum height
                maxHeight: '350px', // Increased maximum height for more content
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
              onClick={() => handleOpenAttackPath(report.id)}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" sx={{ color: '#ffffff', fontWeight: 'bold', marginBottom: 1 }}>
                  {report.type}
                </Typography>
                <Typography variant="body2" sx={{ color: '#bbbbbb', marginBottom: 1, fontSize: '1rem' }}>
                  {report.time}
                </Typography>
                <Typography variant="body2" sx={{ color: '#bbbbbb', marginBottom: 1, fontSize: '1rem' }}>
                  Source: {report.source || 'Unknown'}
                </Typography>
                <Typography variant="body2" sx={{ color: '#bbbbbb', fontSize: '1rem', marginBottom: 1 }}>
                  Description: {report.description || 'No additional details available.'}
                </Typography>

                {/* Enhanced Severity Chip */}
                <Chip
                  label={report.severity}
                  sx={{
                    backgroundColor:
                      report.severity === 'Critical' ? '#ff1744' :
                      report.severity === 'High' ? '#ffa000' :
                      '#1976d2',
                    color: '#FFFFFF',
                    fontWeight: 'bold',
                    marginTop: '8px',
                    width: 'fit-content',
                    padding: '8px',
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '0.9rem',
                  }}
                  icon={
                    report.severity === 'Critical' ? <ErrorOutlineIcon style={{ fontSize: '20px' }} /> :
                    report.severity === 'High' ? <WarningAmberIcon style={{ fontSize: '20px' }} /> :
                    <InfoIcon style={{ fontSize: '20px' }} />
                  }
                />
              </CardContent>

              {/* Status Chip */}
              <Box sx={{ textAlign: 'center', marginTop: 2 }}>
                <Chip
                  label={report.status}
                  sx={{
                    color: report.status === 'Resolved' ? '#66bb6a' : report.status === 'In Progress' ? '#ffb300' : '#e57373',
                    backgroundColor:
                      report.status === 'Resolved' ? '#66bb6a22' :
                      report.status === 'In Progress' ? '#ffb30022' : '#e5737322',
                    fontWeight: 'bold',
                    padding: '10px 16px',
                    borderRadius: '8px',
                    textAlign: 'center',
                    fontSize: '0.9rem',
                  }}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Attack Path Modal */}
      <Modal open={openAttackPath} onClose={handleCloseAttackPath} aria-labelledby="attack-path-modal-title">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '85vw',
            height: '65vh',
            bgcolor: '#2b2f44',
            borderRadius: '12px',
            boxShadow: 24,
            p: 4,
            color: '#FFFFFF',
            overflowY: 'auto',
          }}
        >
          <Typography
            id="attack-path-modal-title"
            variant="h5"
            sx={{ mb: 3, color: '#56CCF2', textAlign: 'center', fontWeight: 'bold' }}
          >
            Attack Path for {reports.find((report) => report.id === selectedAttackPath)?.type}
          </Typography>
          <AttackPathMap nodesData={attackPaths[selectedAttackPath]} />
          <Button
            onClick={handleCloseAttackPath}
            variant="contained"
            sx={{
              display: 'block',
              margin: '20px auto 0',
              backgroundColor: '#ff4081',
              color: '#FFFFFF',
              padding: '12px 28px',
              fontWeight: 'bold',
              '&:hover': { backgroundColor: '#ff79a2' },
            }}
          >
            Return to Reports
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default IncidentReports;
