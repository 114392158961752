import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const OnetrustIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Onetrust Integration"
    integrationName="onetrust"
  />
);


export default OnetrustIntegrationDialog;
