import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const Rapid7IntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Rapid7 Integration"
    integrationName="rapid7"
  />
);


export default Rapid7IntegrationDialog;
