import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const MimecastIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Mimecast Integration"
    integrationName="mimecast"
  />
);

export default MimecastIntegrationDialog;
