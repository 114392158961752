// LoginForm.js
import React, { useState } from 'react';
import { Dialog, DialogContent, Button, Typography, Box, Container, TextField, CircularProgress } from '@mui/material';
import axios from 'axios';
import AWS from 'aws-sdk';
import './SignupForm.css';

const LoginForm = ({ open, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [view, setView] = useState('form'); // Toggle between login form and password reset
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null); // Success message state
  const [loading, setLoading] = useState(false); // Loading state for button

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage(null);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage(null);
  };

const handleLoginSubmit = async (event) => {
  event.preventDefault(); // Prevent default form submission (no page reload initially)

  if (!email || !password) {
    setErrorMessage('Please enter both email and password.');
    return;
  }

  setLoading(true);
  try {
    const cognito = new AWS.CognitoIdentityServiceProvider({
      region: 'eu-west-1', // Update with your AWS region
    });

    const params = {
      AuthFlow: 'USER_PASSWORD_AUTH',
      ClientId: '578gfrjo8hjvdrghpj9fbpn2k0', // Replace with your Cognito App Client ID
      AuthParameters: {
        USERNAME: email,
        PASSWORD: password,
      },
    };

    const authResponse = await cognito.initiateAuth(params).promise();
    const accessToken = authResponse.AuthenticationResult.IdToken;

    // Store the access token locally
    localStorage.setItem('accessToken', accessToken);

    setSuccessMessage('Successfully logged in!');
    setTimeout(() => {
      setSuccessMessage(null);
      onClose(); // Close the dialog
      window.location.reload(); // Force a page reload after login success
    }, 1000);
  } catch (error) {
    console.error("Error during login:", error);
    setErrorMessage("Invalid email or password. Please try again.");
  } finally {
    setLoading(false);
  }
};



  const handleResetPasswordClick = () => {
    setView('resetPassword');
  };



  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: '#131629',
          borderRadius: '8px',
          boxShadow: 'none',
          width: '100%',
        },
      }}
    >
      <DialogContent sx={{ padding: '10px', borderRadius: '12px' }}>
        <Container>
          <Box className="login-form-container" sx={{ textAlign: 'center', color: '#ffffff' }}>
            {view === 'form' && (
              <form onSubmit={handleLoginSubmit}>
                <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                  Log In
                </Typography>

                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  fullWidth
                  sx={{
                    marginBottom: '16px',
                    '& .MuiInputLabel-root': { color: '#bbb' },
                    '& .MuiInputBase-root': { color: '#ffffff', backgroundColor: '#2a2d3e' },
                    borderRadius: '8px',
                  }}
                />

                <TextField
                  label="Password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  fullWidth
                  sx={{
                    marginBottom: '16px',
                    '& .MuiInputLabel-root': { color: '#bbb' },
                    '& .MuiInputBase-root': { color: '#ffffff', backgroundColor: '#2a2d3e' },
                    borderRadius: '8px',
                  }}
                />

                {errorMessage && (
                  <Typography variant="body2" sx={{ marginBottom: '20px', color: '#FF6666' }}>
                    {errorMessage}
                  </Typography>
                )}

                {successMessage && (
                  <Typography variant="body2" sx={{ marginBottom: '20px', color: '#66FF66' }}>
                    {successMessage}
                  </Typography>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={loading}
                  sx={{
                    padding: '14px',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    backgroundImage: 'linear-gradient(135deg, #4a90e2, #56CCF2)',
                    color: '#ffffff',
                    borderRadius: '8px',
                    '&:hover': {
                      backgroundImage: 'linear-gradient(135deg, #6d28d9, #ec4899)',
                      boxShadow: '0 8px 16px rgba(109, 40, 217, 0.5)',
                    },
                  }}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Log In"}
                </Button>

              </form>
            )}


          </Box>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default LoginForm;
