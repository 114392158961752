import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const PhishTankIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="PhishTank Integration"
    integrationName="phishtank"
  />
);


export default PhishTankIntegrationDialog;
