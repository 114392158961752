import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const GoogleCloudIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Google Cloud Integration"
    integrationName="googlecloud"
  />
);


export default GoogleCloudIntegrationDialog;
