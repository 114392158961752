import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Dialog, Box, IconButton, TextField, Button, Typography, Divider, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import LoginDialog from './LoginForm'; // Import your login dialog component
import UpgradeDialog from './UpgradeDialog';

const ThreatAIChat = ({ open, onClose, initialData }) => {
  const [messages, setMessages] = useState([
    { type: 'bot', text: 'Hello! How can I assist you with Threat Intelligence?' },
  ]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false); // State to control login dialog visibility

  const examplePrompts = [
    'What are the latest threats?',
    'Analyze a suspicious IP address.',
    'Show me recent intel.',
    'Perform a Security Assessment',
  ];

  // Load initial data if provided
  useEffect(() => {
    if (initialData) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: 'bot', text: `Here's the report ID you requested to start with: ${initialData}` },
      ]);
    }
  }, [initialData]);

  const handleSend = async (message) => {
    const text = message || inputValue;
    if (text.trim()) {
      // Add user's message to chat
      setMessages([...messages, { type: 'user', text }]);
      setInputValue('');
      setLoading(true); // Set loading to true

 try {
        const accessToken = localStorage.getItem('accessToken');

        const response = await fetch('https://community.webamon.co.uk/analyst', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ message: text }),
        });

        if (response.status === 401 || response.status === 403) {
          // If 401 or 403, show the login dialog
          setShowLoginDialog(true);
          setLoading(false);
          return;
        }

        const data = await response.json();
        const chatId = data.chat_id;

        if (!chatId) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { type: 'bot', text: "Sorry, there was an issue with your request. Please try again later." },
          ]);
          setLoading(false);
          return;
        }

        const maxRetries = 45;
        let attempts = 0;

        while (attempts < maxRetries) {
          const chatResponse = await fetch(`https://community.webamon.co.uk/analyst/${chatId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (chatResponse.status === 401 || chatResponse.status === 403) {
            setShowLoginDialog(true);
            setLoading(false);
            return;
          } else if (chatResponse.status === 200) {
            const responseData = await chatResponse.json();
            setMessages((prevMessages) => [
              ...prevMessages,
              { type: 'bot', text: responseData.response || "I'm analyzing your query, please hold on..." },
            ]);
            break;
          } else if (chatResponse.status === 400) {
            attempts++;
            console.log(`Attempt ${attempts} failed. Retrying in 1 second...`);
            await new Promise((resolve) => setTimeout(resolve, 3000));
          } else {
            throw new Error('Unexpected response from server');
          }
        }

        if (attempts === maxRetries) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { type: 'bot', text: "Sorry, I couldn't process your request. Please try again later." },
          ]);
        }

      } catch (error) {
        console.error("Error sending message:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { type: 'bot', text: "Sorry, I couldn't process your request. Please try again later." },
        ]);
      } finally {
        setLoading(false);
      }
    }
  };


  const handlePromptClick = (prompt) => {
    handleSend(prompt);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: '#191b2d',
          color: '#ffffff',
          width: '1600px',
          height: '1700px',
          maxWidth: 'none',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >


          {showLoginDialog && <LoginDialog open={showLoginDialog} onClose={() => setShowLoginDialog(false)} />}

      {/* Header */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 20px', backgroundColor: '#1f2235' }}>
        <Typography variant="h6" sx={{ color: '#56CCF2', fontSize: '1.4rem' }}>Threat-AI Chat</Typography>
        <IconButton onClick={onClose} sx={{ color: '#ffffff' }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Chat Display Area */}
      <Box
        sx={{
          flexGrow: 1,
          padding: '20px',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          backgroundColor: '#1f2235',
        }}
      >
        {messages.map((msg, index) => (
          <Box
            key={index}
            sx={{
              alignSelf: msg.type === 'user' ? 'flex-end' : 'flex-start',
              backgroundColor: msg.type === 'user' ? '#343b6f' : '#2b2f44',
              color: '#ffffff',
              padding: '12px 16px',
              borderRadius: '12px',
              maxWidth: '80%',
              fontSize: '1.1rem',
              wordWrap: 'break-word',
            }}
          >
            {msg.type === 'bot' ? (
              <ReactMarkdown>{msg.text}</ReactMarkdown>
            ) : (
              msg.text
            )}
          </Box>
        ))}
        {/* Loading Spinner */}
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <CircularProgress color="secondary" />
          </Box>
        )}
      </Box>

      <Divider sx={{ backgroundColor: '#343b6f' }} />

      {/* Example Prompts */}
      <Box sx={{ padding: '10px 20px', backgroundColor: '#1f2235' }}>
        <Typography variant="subtitle1" sx={{ color: '#56CCF2', marginBottom: '8px' }}>
          Try one of these:
        </Typography>
        <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
          {examplePrompts.map((prompt, index) => (
            <Button
              key={index}
              variant="outlined"
              onClick={() => handlePromptClick(prompt)}
              sx={{
                color: '#ffffff',
                borderColor: '#56CCF2',
                textTransform: 'none',
                fontSize: '0.9rem',
                '&:hover': { borderColor: '#4a90e2', backgroundColor: '#2b2f44' },
              }}
            >
              {prompt}
            </Button>
          ))}
        </Box>
      </Box>

      <Divider sx={{ backgroundColor: '#343b6f' }} />

      {/* Input Area */}
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px 20px', backgroundColor: '#1f2235' }}>
        <TextField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Type your message..."
          variant="outlined"
          fullWidth
          multiline
          minRows={1}
          sx={{
            backgroundColor: '#2b2f44',
            borderRadius: '8px',
            input: { color: '#ffffff', fontSize: '1.1rem' },
            '& .MuiInputBase-input': { color: '#ffffff' },
            '& .MuiInputBase-input::placeholder': { color: '#ffffff', opacity: 1 },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#56CCF2',
              },
              '&:hover fieldset': {
                borderColor: '#4a90e2',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#4a90e2',
              },
            },
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSend();
            }
          }}
        />
        <IconButton onClick={() => handleSend()} sx={{ color: '#56CCF2', marginLeft: '10px', fontSize: '1.5rem' }}>
          <SendIcon fontSize="large" />
        </IconButton>
      </Box>
    </Dialog>
  );
};

export default ThreatAIChat;
