import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const ServiceNowIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="ServiceNow Integration"
    integrationName="servicenow"
  />
);

export default ServiceNowIntegrationDialog;
