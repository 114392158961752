import React, { useState } from 'react';
import { Box, Typography, FormControl, Select, MenuItem, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { saveAs } from 'file-saver';
import { writeFile, utils } from 'xlsx';
import logData from './logData.json';
import dayjs from 'dayjs';

const TimelineComponent = () => {
  const [sourceFilter, setSourceFilter] = useState([]);
  const [eventFilter, setEventFilter] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleClearFilters = () => {
    setSourceFilter([]);
    setEventFilter([]);
    setStartDate('');
    setEndDate('');
  };

  // Filter log data based on the selected filters
  const filteredData = logData.filter(log => {
    const matchesSource = sourceFilter.length === 0 || sourceFilter.includes(log.source);
    const matchesEvent = eventFilter.length === 0 || eventFilter.includes(log.event);
    const matchesStartDate = startDate ? dayjs(log.timestamp).isAfter(dayjs(startDate), 'day') || dayjs(log.timestamp).isSame(dayjs(startDate), 'day') : true;
    const matchesEndDate = endDate ? dayjs(log.timestamp).isBefore(dayjs(endDate), 'day') || dayjs(log.timestamp).isSame(dayjs(endDate), 'day') : true;
    return matchesSource && matchesEvent && matchesStartDate && matchesEndDate;
  });

  // Filter sources and events based on the current date range
  const dateFilteredData = logData.filter(log => {
    const matchesStartDate = startDate ? dayjs(log.timestamp).isAfter(dayjs(startDate), 'day') || dayjs(log.timestamp).isSame(dayjs(startDate), 'day') : true;
    const matchesEndDate = endDate ? dayjs(log.timestamp).isBefore(dayjs(endDate), 'day') || dayjs(log.timestamp).isSame(dayjs(endDate), 'day') : true;
    return matchesStartDate && matchesEndDate;
  });

  // Get unique sources based on the date range
  const sources = [...new Set(dateFilteredData.map(log => log.source))];

  // Get unique events based on both the selected sources and date range
  const events = sourceFilter.length > 0
    ? [...new Set(dateFilteredData.filter(log => sourceFilter.includes(log.source)).map(log => log.event))]
    : [...new Set(dateFilteredData.map(log => log.event))];

  const handleExport = () => {
    const worksheet = utils.json_to_sheet(filteredData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Timeline Log');
    writeFile(workbook, 'timeline_log.xlsx');
  };

  return (
    <Box sx={{ padding: 4, minWidth: '800px', minHeight: '600px', backgroundColor: '#1f2233', borderRadius: '12px', color: '#FFFFFF' }}>
      <Typography variant="h4" sx={{ color: '#56CCF2', marginBottom: 2, textAlign: 'center' }}>
        Company-wide Log Timeline
      </Typography>

      <Box sx={{ display: 'flex', gap: 2, marginBottom: 3, flexWrap: 'wrap' }}>
        <FormControl sx={{ width: '200px' }}>
          <Select
            multiple
            value={sourceFilter}
            onChange={(e) => setSourceFilter(e.target.value)}
            displayEmpty
            sx={{ color: '#FFFFFF' }}
            renderValue={(selected) => (selected.length ? selected.join(', ') : 'All Sources')}
          >
            <MenuItem value="">All Sources</MenuItem>
            {sources.map(source => (
              <MenuItem key={source} value={source}>
                <input type="checkbox" checked={sourceFilter.includes(source)} readOnly />
                {source}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: '200px' }}>
          <Select
            multiple
            value={eventFilter}
            onChange={(e) => setEventFilter(e.target.value)}
            displayEmpty
            sx={{ color: '#FFFFFF' }}
            renderValue={(selected) => (selected.length ? selected.join(', ') : 'All Events')}
          >
            <MenuItem value="">All Events</MenuItem>
            {events.map(event => (
              <MenuItem key={event} value={event}>
                <input type="checkbox" checked={eventFilter.includes(event)} readOnly />
                {event}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true, style: { color: '#FFFFFF' } }}
          InputProps={{ style: { color: '#FFFFFF' } }}
          sx={{ width: '200px' }}
        />

        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true, style: { color: '#FFFFFF' } }}
          InputProps={{ style: { color: '#FFFFFF' } }}
          sx={{ width: '200px' }}
        />

        <Button variant="contained" onClick={handleClearFilters} sx={{ backgroundColor: '#FF4C4C' }}>
          Clear Filters
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ backgroundColor: '#2b2f44', borderRadius: '8px', maxHeight: '500px', overflowY: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#2b2f44', color: '#56CCF2', fontWeight: 'bold' }}>Timestamp</TableCell>
              <TableCell sx={{ backgroundColor: '#2b2f44', color: '#56CCF2', fontWeight: 'bold' }}>Source</TableCell>
              <TableCell sx={{ backgroundColor: '#2b2f44', color: '#56CCF2', fontWeight: 'bold' }}>Event</TableCell>
              <TableCell sx={{ backgroundColor: '#2b2f44', color: '#56CCF2', fontWeight: 'bold' }}>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.map((log, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ color: '#FFFFFF' }}>{dayjs(log.timestamp).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                  <TableCell sx={{ color: '#FFFFFF' }}>{log.source}</TableCell>
                  <TableCell sx={{ color: '#FFFFFF' }}>{log.event}</TableCell>
                  <TableCell sx={{ color: '#FFFFFF' }}>{log.description}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} sx={{ textAlign: 'center', color: '#bbbbbb' }}>
                  No logs available for the selected filters
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Button variant="contained" onClick={handleExport} sx={{ marginTop: 3, backgroundColor: '#56CCF2' }}>
        Export to Excel
      </Button>
    </Box>
  );
};

export default TimelineComponent;
