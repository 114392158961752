import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const ExabeamIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Exabeam Integration"
    integrationName="exabeam"
  />
);

export default ExabeamIntegrationDialog;
