import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const GreynoiseIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Greynoise Integration"
    integrationName="greynoise"
  />
);

export default GreynoiseIntegrationDialog;
