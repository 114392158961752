import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const TaniumIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Tanium Integration"
    integrationName="tanium"
  />
);


export default TaniumIntegrationDialog;
