import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const ElasticsearchIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Elasticsearch Integration"
    integrationName="elasticsearch" // This will load fields for `slack` from integrations.json
  />
);


export default ElasticsearchIntegrationDialog;
