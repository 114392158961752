import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';

// Sample data for exposed credentials and incidents over time
const exposedData = [
  { email: 'john.doe@example.com', source: 'Data Breach 1', date: '2023-09-01' },
  { email: 'jane.smith@example.com', source: 'Data Breach 2', date: '2023-08-15' },
  { email: 'mike.jones@example.com', source: 'Data Breach 3', date: '2023-07-20' },
  { email: 'emily.davis@example.com', source: 'Data Breach 4', date: '2023-06-10' },
  { email: 'robert.brown@example.com', source: 'Data Breach 5', date: '2023-05-25' },
  { email: 'alice.johnson@example.com', source: 'Data Breach 1', date: '2023-09-02' },
  { email: 'bob.miller@example.com', source: 'Data Breach 2', date: '2023-09-03' },
  { email: 'carol.taylor@example.com', source: 'Data Breach 3', date: '2023-09-04' },
  { email: 'dave.wilson@example.com', source: 'Data Breach 4', date: '2023-09-05' },
  { email: 'eve.james@example.com', source: 'Data Breach 5', date: '2023-09-06' },
];

// Sample data for credential exposures over time
const exposureTrendData = [
  { date: '2023-09-01', count: 2 },
  { date: '2023-09-02', count: 1 },
  { date: '2023-09-03', count: 1 },
  { date: '2023-09-04', count: 1 },
  { date: '2023-09-05', count: 1 },
  { date: '2023-09-06', count: 1 },
];

// Sample data for exposure by source
const sourceData = [
  { source: 'Data Breach 1', count: 3 },
  { source: 'Data Breach 2', count: 2 },
  { source: 'Data Breach 3', count: 3 },
  { source: 'Data Breach 4', count: 2 },
  { source: 'Data Breach 5', count: 1 },
];

const CredentialExposure = () => {
  return (
    <Box
      sx={{
        padding: 3,
        maxWidth: '100%',
        backgroundColor: '#2b2f44',
        borderRadius: '12px',
        boxShadow: '0 6px 30px rgba(0, 0, 0, 0.5)',
      }}
    >
      <Typography variant="h5" sx={{ color: '#56CCF2', marginBottom: 2, textAlign: 'center' }}>
        Credential Exposure Overview
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {/* Exposure Trend Chart Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ color: '#ffffff', marginBottom: 2, textAlign: 'center' }}>
            Credential Exposure Over Time
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={exposureTrendData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#444" />
              <XAxis dataKey="date" stroke="#ffffff" />
              <YAxis stroke="#ffffff" />
              <Tooltip contentStyle={{ backgroundColor: '#2b2f44', borderColor: '#56CCF2' }} />
              <Legend verticalAlign="top" align="right" height={36} />
              <Line type="monotone" dataKey="count" stroke="#56CCF2" strokeWidth={2} activeDot={{ r: 6 }} />
            </LineChart>
          </ResponsiveContainer>
        </Grid>

        {/* Source Exposure Chart Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ color: '#ffffff', marginBottom: 2, textAlign: 'center' }}>
            Credential Exposures by Source
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={sourceData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#444" />
              <XAxis dataKey="source" stroke="#ffffff" />
              <YAxis stroke="#ffffff" />
              <Tooltip contentStyle={{ backgroundColor: '#2b2f44', borderColor: '#56CCF2' }} />
              <Legend verticalAlign="top" align="right" height={36} />
              <Bar dataKey="count" fill="#56CCF2" />
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        {/* Table Section */}
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ backgroundColor: '#3b3f56' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: '#ffffff', fontWeight: 'bold' }}>Email Address</TableCell>
                  <TableCell sx={{ color: '#ffffff', fontWeight: 'bold' }}>Source</TableCell>
                  <TableCell sx={{ color: '#ffffff', fontWeight: 'bold' }}>Date of Exposure</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exposedData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ color: '#ffffff' }}>{row.email}</TableCell>
                    <TableCell sx={{ color: '#ffffff' }}>{row.source}</TableCell>
                    <TableCell sx={{ color: '#ffffff' }}>{row.date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* Additional Insights Section */}
      <Box sx={{ marginTop: 4, textAlign: 'center' }}>
        <Typography variant="h6" sx={{ color: '#ffffff', marginBottom: 2 }}>
          Additional Insights
        </Typography>
        <Typography variant="body1" sx={{ color: '#bbbbbb' }}>
          The organization is currently facing a total of {exposedData.length} incidents, with {exposedData.length} unique email addresses exposed.
        </Typography>
      </Box>
    </Box>
  );
};

export default CredentialExposure;
