import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const AzureIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Azure Integration"
    integrationName="azure"
  />
);

export default AzureIntegrationDialog;
