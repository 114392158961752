import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const GoogleSafeIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Google Safe Browsing Integration"
    integrationName="googlesafebrowsing"
  />
);

export default GoogleSafeIntegrationDialog;
