// Updated AuditTrailDashboard.js

import React, { useState } from 'react';
import { Box, Typography, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DescriptionIcon from '@mui/icons-material/Description';
import eventData from './auditTrailData.json';

const AuditTrailDashboard = () => {
  const [eventTypeFilter, setEventTypeFilter] = useState('All');

  const filteredData = eventTypeFilter === 'All' ? eventData.auditTrail : eventData.auditTrail.filter(event => event.eventType === eventTypeFilter);

  const eventTypeCounts = eventData.auditTrail.reduce((acc, event) => {
    acc[event.eventType] = (acc[event.eventType] || 0) + 1;
    return acc;
  }, {});

  return (
    <Box
      sx={{
        padding: 5,
        width: '900px', // Fixed width to fit content and center on screen
        height: '750px', // Fixed height for consistency
        margin: '0 auto',
        backgroundColor: '#1f2233',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
        overflow: 'hidden',
      }}
    >
      {/* Dashboard Title */}
      <Typography variant="h4" sx={{ color: '#56CCF2', marginBottom: 3, textAlign: 'center', fontWeight: 'bold' }}>
        Audit Trail Dashboard
      </Typography>

      {/* Event Type Filter */}
      <FormControl variant="outlined" sx={{ width: '250px', marginBottom: 4 }}>
        <InputLabel sx={{ color: '#56CCF2', fontSize: '1.4rem' }}>Event Type</InputLabel>
        <Select
          value={eventTypeFilter}
          onChange={(e) => setEventTypeFilter(e.target.value)}
          label="Event Type"
          sx={{ color: '#FFFFFF', fontSize: '1.3rem' }}
        >
          <MenuItem value="All" sx={{ fontSize: '1.2rem' }}>All</MenuItem>
          {Object.keys(eventTypeCounts).map(type => (
            <MenuItem key={type} value={type} sx={{ fontSize: '1.2rem' }}>
              {type} ({eventTypeCounts[type]})
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Event Log */}
      <Box
        sx={{
          width: '100%',
          maxHeight: '600px', // Fixed max height to avoid vertical resizing
          overflowY: 'auto', // Scrollable content for the event log
          padding: 3,
          backgroundColor: '#2b2f44',
          borderRadius: '8px',
        }}
      >
        <Grid container spacing={3} sx={{ padding: 2 }}>
          {filteredData.map((event, index) => (
            <Grid
              item
              xs={12}
              key={index}
              sx={{
                padding: 3,
                backgroundColor: '#1f2533',
                borderRadius: '8px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                overflowWrap: 'break-word', // Handle long details gracefully
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <PersonIcon sx={{ color: '#56CCF2', marginRight: 1 }} />
                <Typography variant="body1" sx={{ color: '#FFFFFF', fontSize: '1.3rem' }}>
                  <b>User:</b> {event.user}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <EventIcon sx={{ color: '#FFB300', marginRight: 1 }} />
                <Typography variant="body2" sx={{ color: '#FFB300', fontSize: '1.2rem' }}>
                  <b>Event:</b> {event.eventType}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <AccessTimeIcon sx={{ color: '#82ca9d', marginRight: 1 }} />
                <Typography variant="body2" sx={{ color: '#bbbbbb', fontSize: '1.2rem' }}>
                  <b>Time:</b> {event.timestamp}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <DescriptionIcon sx={{ color: '#82b1ff', marginRight: 1 }} />
                <Typography variant="body2" sx={{ color: '#bbbbbb', fontSize: '1.2rem' }}>
                  <b>Details:</b> {event.details}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default AuditTrailDashboard;
