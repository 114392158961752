import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const SplunkIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Splunk Integration"
    integrationName="splunk"
  />
);


export default SplunkIntegrationDialog;
