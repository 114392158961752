import React from 'react';
import IntegrationDialogTemplate from './IntegrationDialogTemplate';

const QualysIntegrationDialog = ({ open, onClose }) => (
  <IntegrationDialogTemplate
    open={open}
    onClose={onClose}
    title="Qualys Integration"
    integrationName="qualys"
  />
);


export default QualysIntegrationDialog;
