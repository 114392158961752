// src/components/Navbar.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Button } from '@mui/material';
import Dashboards from './Dashboards';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm'; // Import SignupForm component
import UpgradeDialog from './UpgradeDialog';

// Utility function to check if the user is authenticated
const isAuthenticated = () => {
  return !!localStorage.getItem('accessToken'); // Checks for accessToken in localStorage
};

const Navbar = () => {
  const [openDashboards, setOpenDashboards] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false); // State for Signup dialog

  const handleOpenDashboards = () => {
    setOpenDashboards(true);
  };

  const handleCloseDashboards = () => {
    setOpenDashboards(false);
  };

  const handleOpenLogin = () => {
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleOpenSignup = () => {
    setOpenSignup(true);
  };

  const handleCloseSignup = () => {
    setOpenSignup(false);
  };

  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: 'transparent', boxShadow: 'none', top: 0, right: 0, padding: '8px 16px', zIndex: 1300 }}>
        <Toolbar sx={{ justifyContent: 'flex-end', padding: 0 }}>
          {isAuthenticated() ? (
            <Button
              onClick={handleOpenDashboards}
              variant="contained"
              sx={{
                backgroundImage: 'linear-gradient(135deg, #4a90e2, #56CCF2)',
                color: '#ffffff',
                fontWeight: 'bold',
                borderRadius: '8px',
                padding: '6px 16px',
                '&:hover': {
                  backgroundImage: 'linear-gradient(135deg, #56CCF2, #4a90e2)',
                  boxShadow: '0px 4px 10px rgba(74, 144, 226, 0.5)',
                },
              }}
            >
              Quota
            </Button>
          ) : (
            <>
              <Button
                onClick={handleOpenLogin}
                variant="contained"
                sx={{
                  backgroundImage: 'linear-gradient(135deg, #4a90e2, #56CCF2)',
                  color: '#ffffff',
                  fontWeight: 'bold',
                  borderRadius: '8px',
                  padding: '6px 16px',
                  marginLeft: '16px',
                  '&:hover': {
                    backgroundImage: 'linear-gradient(135deg, #56CCF2, #4a90e2)',
                    boxShadow: '0px 4px 10px rgba(74, 144, 226, 0.5)',
                  },
                }}
              >
                Login
              </Button>
              <Button
                onClick={handleOpenSignup}
                variant="contained"
                sx={{
                  backgroundImage: 'linear-gradient(135deg, #4a90e2, #56CCF2)',
                  color: '#ffffff',
                  fontWeight: 'bold',
                  borderRadius: '8px',
                  padding: '6px 16px',
                  marginLeft: '16px',
                  '&:hover': {
                    backgroundImage: 'linear-gradient(135deg, #56CCF2, #4a90e2)',
                    boxShadow: '0px 4px 10px rgba(74, 144, 226, 0.5)',
                  },
                }}
              >
                Signup
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>

      {/* Dashboards Component */}
//      <Dashboards open={openDashboards} onClose={handleCloseDashboards} />

<UpgradeDialog open={openDashboards} onClose={handleCloseDashboards} />

      {/* LoginForm Dialog */}
      <LoginForm open={openLogin} onClose={handleCloseLogin} />

      {/* SignupForm Dialog */}
      <SignupForm open={openSignup} onClose={handleCloseSignup} />
    </>
  );
};

export default Navbar;
